/** @format */

import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { API_URL } from "../http";
import ViewPlants from "../pages/ViewPlants/ViewPlants";
import CurrentPlant from "../pages/CurrentPlant/CurrentPlant";
import MainPage from "../pages/MainPage/MainPage";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import EmailVerify from "../pages/EmailVerify/EmailVerify";
import CheckEmail from "../pages/CheckEmail/CheckEmail";
import BuyPlants from "../pages/BuyPlants/BuyPlants";
import Checkout from "../pages/BuyPlants/Components/Main/Components/Checkout/Checkout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Settings from "../pages/Settings/Settings";
import AdminDashboard from "../pages/AdminDashboard/AdminDashboard";
import AdminFinances from "../pages/AdminFinances/AdminFinances";
import AdminSettings from "../pages/AdminSettings/AdminSettings";
import Finances from "../pages/Finances/Finances";
import Verification from "../pages/Verification/Verification";
import PasswordReset from "../pages/PasswordReset/PasswordReset";
import CheckEmailReset from "../pages/CheckEmailReset/CheckEmailReset";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Privacy from "../pages/Privacy/Privacy";
import Press from '../pages/Press/Press'
import PassChanged from "../pages/PassChanged/PassChanged";
import Help from "../pages/Help/Help";
import News from "../pages/News/News";
import Location from "../pages/Location/Location";
import { Context } from "../index";
import { useObserver } from "mobx-react-lite";
import AdminStatistics from "../pages/AdminStatistics/AdminStatistics";
import Imprint from "../pages/Imprint/Imprint";
import { AGB } from "../pages/AGB/AGB";
import { RiskInformation } from "../pages/RiskInformation/RiskInformation";
// import Questionnaire from "../pages/Questionnaire/Questionnaire";
// import Result from "../pages/Questionnaire/Components/Main/Components/Result/Result";
import Order from "../pages/BuyPlants/Components/Main/Components/Order/Order";
import { useTranslation } from "react-i18next";
// import Loader from "../components/Loader/Loader";
import postRequest from "../helpers/postRequest";
import getRequest from "../helpers/getRequest";
import AdminDocuments from "../pages/AdminDocuments/AdminDocuments";
import AdminTokens from "../pages/AdminTokens/AdminTokens";
import CoinbaseResult from "../pages/CoinbaseResult/CoinbaseResult";
import Shop from "../pages/Shop/Shop";
import CheckContract from "../pages/CheckContractEmail/CheckContractEmail";
import { useLocation } from "react-router-dom";
import en from "../img/en.svg";
import de from "../img/de.svg";
import { Withdrawal } from "../pages/Withdrawal/Withdrawal";
import FinancesStats from "../pages/Finances/Components/Main/Components/Statistics/Statistics";
import Contact from "../pages/Contact/Contact";
import SingleNews from "../pages/SingleNews/SingleNews";
import Cookie from "../components/Cookie/Cookie";
import CoinbaseRedirects from "../pages/CoinbaseRedirects/CoinbaseRedirects";
import Marketplace from "../pages/Marketplace/Marketplace";
function App() {
  const [user, setUser] = useState(true);
  const [userData, setUserData] = useState(true);

  const { store } = useContext(Context);
  const { t } = useTranslation();

  const location = useLocation();

  const [currentLang, setCurrentLang] = useState({
    lang: navigator.language.toLowerCase().includes("de") ? "DE" : "EN",
    icon: navigator.language.toLowerCase().includes("de") ? de : en,
    id: 1,
  });

  // Вынести в mobx || redux
  const [amountPlants, setAmountPlants] = useState(1);
  const [sumOfPlants, setSumOfPlants] = useState(250);
  const [loading, setLoading] = useState(false);
  const [enableHarvestSelling, setEnableHarvestSelling] = useState(false);
  const [periodicityHarvestSelling, setPeriodicityHarvestSelling] = useState(4);
  const [role] = useState(() => localStorage.getItem("role"));
  const [openModal, setIsOpenModal] = useState(false);
  const [referalValue, setReferalValue] = useState("Waiting...");

  const { isAuth } = useObserver(() => ({
    isAuth: store.isAuth,
  }));

  useEffect(() => {
    getRequest("v1/auth/whoami")
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => console.log(error));

    if (!localStorage.getItem("i18n")) {
      setCurrentLang({
        lang: navigator.language.toLowerCase().includes("de") ? "DE" : "EN",
        image: navigator.language.toLowerCase().includes("de") ? de : en,
      });

      return;
    }

    if (localStorage.getItem("i18n") === "EN") {
      setCurrentLang({ lang: "EN", image: en });
    } else {
      setCurrentLang({ lang: "DE", image: de });
    }
  }, []);

  useEffect(() => {
    if (!!isAuth) {
      setUser(true);
    }
  }, [isAuth]);

  useEffect(() => {
    if (isAuth === false) {
      setUser(false);
      // navigate('/')
    }
  }, [isAuth]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      // store.checkAuth();
    } else store.isAuth = false;

    setLoading(true);

    getRequest("v1/constants/plant/price")
      .then((response) => {
        setSumOfPlants(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (openModal === true && role === "MAIN_SALE") {
      postRequest("v1/referral-invites/invite-head", {})
        .then((response) => setReferalValue(response.data?.invite_link))
        .catch((error) => console.log(error));
    }

    if (openModal === true && role === "HEAD_OF_SALES") {
      postRequest("v1/referral-invites", {})
        .then((response) => setReferalValue(response.data?.invite_link))
        .catch((error) => console.log(error));
    }
    if (openModal === true && role === "SALE") {
      getRequest("v1/referrals/my-code")
        .then((response) => setReferalValue(response.data?.invite_link))
        .catch((error) => console.log(error));
    }
  }, [openModal, role]);
  useEffect(() => {
    if (
      location.pathname.includes("/ad") ||
      location.pathname.includes("/dect")
    ) {
      localStorage.setItem("isDectAd", true);
    }
    // else {
    //   localStorage.removeItem("isDectAd");
    // }
  }, []);

  return (
    <>
      <Cookie />

      {/* {loading && <Loader />} */}
      <Routes>
        <Route
          path="/"
          element={
            <MainPage
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="/anbaugesellschaft-einladung"
          element={
            <Register
              currLang={currentLang}
              setCurrLang={setCurrentLang}
              roleRegisterProp={"sale"}
              inviteCodeProp={"Anbaugesellschaft-Einladung"}
            />
          }
        />
        <Route
          path="/ad/:id"
          element={
            <MainPage
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
              international
            />
          }
        />
        <Route
          path="/ad/:id/register"
          element={
            <MainPage
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
              international
            />
          }
        />
        <Route
          path="reset-password/:id"
          element={
            <ResetPassword
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="/news/:id"
          element={
            <SingleNews
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route path="*" element={<Navigate replace to="/register" />} />
        <Route
          path="/contact"
          element={
            <Contact
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route path="/coinbase/:orderId" element={<CoinbaseRedirects />} />

        {/* {!isAuth ? ( */}
        <Route
          path="/login"
          element={
            <Login currentLang={currentLang} setCurrentLang={setCurrentLang} />
          }
        />
        <Route
          path="admin/settings"
          element={
            <AdminSettings
              role={role}
              openModal={openModal}
              setIsOpenModal={setIsOpenModal}
              referalValue={referalValue}
              setReferalValue={setReferalValue}
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        {/* ) : (
          <Route path="/login" element={<Navigate replace to="/" />} />
        )} */}
        <Route
          path="/register"
          element={
            <Register currLang={currentLang} setCurrLang={setCurrentLang} />
          }
        />
        <Route
          path="/order/confirm/:orderId"
          element={
            <CoinbaseResult
              type="success"
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="/order/cancel/:orderId"
          element={
            <CoinbaseResult
              type="cancel"
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="/register/ref/:id"
          element={
            <Register currLang={currentLang} setCurrLang={setCurrentLang} />
          }
        />
        <Route
          path="/register/referral/:id"
          element={
            <MainPage
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="/register/:sale_id"
          element={
            <Register currLang={currentLang} setCurrLang={setCurrentLang} />
          }
        />

        <Route
          path="/reset-password"
          element={
            <PasswordReset
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="verify-user/:id"
          element={
            <EmailVerify
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />

        <Route
          path="check-email"
          element={
            <CheckEmail
              text={t("Verification link was sent to your email address.")}
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="check-email-id"
          element={
            <CheckEmail
              text={"Referal id was sent to your email address. "}
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />

        <Route
          path="check-contract"
          element={
            <CheckContract
              smallText={t(
                "Please confirm it in an email. If you don’t receive our mail please search in the SPAM folder in your Mail app."
              )}
              buttonText={t("Go to Main page")}
              text={t("We sent you a contract to this email:")}
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="/check-email-reset"
          element={
            <CheckEmailReset
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="/password-changed"
          element={
            <PassChanged
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="/imprint"
          element={
            <Imprint
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="/agb"
          element={
            <AGB currentLang={currentLang} setCurrentLang={setCurrentLang} />
          }
        />
        <Route
          path="/privacy"
          element={
            <Privacy
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />
        <Route
          path="/press"
          element={
            <Press currentLang={currentLang} setCurrentLang={setCurrentLang} user={user} />
          }
        />
        <Route
          path="/risk-information"
          element={
            <RiskInformation
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />

        <Route
          path="/widerrufsrecht"
          element={
            <Withdrawal
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
            />
          }
        />

        {user ? (
          <>
            {/* ttttmmmmmppp  <Route path="/questionnaire" element={<Questionnaire />} /> */}
            {/*  ttttmmmmmpppp <Route path="/questionnaire/result" element={<Result />} /> */}

            <Route
              path="/verify"
              element={
                <Verification
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="/finances"
              element={
                <Finances
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="/finances/statistics"
              element={
                <FinancesStats
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="/news"
              element={
                <News
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="/marketplace"
              element={
                <Marketplace
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="/shop"
              element={
                <Shop
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />

            {userData.tokens_count > 0 && (
              <Route
                path="/view"
                element={
                  <ViewPlants
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}
                  />
                }
              />
            )}
            <Route
              path="/view/:id"
              element={
                <CurrentPlant
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />

            {/* user routes temp here */}
            <Route
              path="/order/:id"
              element={
                <Order
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="/checkout"
              element={
                <Checkout
                  amountPlants={amountPlants}
                  sumOfPlants={sumOfPlants}
                  enableHarvestSelling={enableHarvestSelling}
                  periodicityHarvestSelling={periodicityHarvestSelling}
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="/bestellung/:orderId"
              element={
                <Checkout
                  amountPlants={amountPlants}
                  sumOfPlants={sumOfPlants}
                  enableHarvestSelling={enableHarvestSelling}
                  periodicityHarvestSelling={periodicityHarvestSelling}
                  type="disabled"
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            {role === "CLIENT" && (
              <Route
                path="/buy"
                element={
                  <BuyPlants
                    amountPlants={amountPlants}
                    setAmountPlants={setAmountPlants}
                    sumOfPlants={sumOfPlants}
                    setSumOfPlants={setSumOfPlants}
                    enableHarvestSelling={enableHarvestSelling}
                    setEnableHarvestSelling={setEnableHarvestSelling}
                    setPeriodicityHarvestSelling={setPeriodicityHarvestSelling}
                    periodicityHarvestSelling={periodicityHarvestSelling}
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}
                  />
                }
              />
            )}
            {/* ttttmmmmmppppp <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route
              path="/help"
              element={
                <Help
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="/settings"
              element={
                <Settings
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="/location"
              element={
                <Location
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            {role === "SALE" ||
            role === "MAIN_SALE" ||
            role === "HEAD_OF_SALES" ? (
              <>
                <Route
                  path="/dashboard"
                  element={<Navigate replace to="/admin/dashboard" />}
                />
                <Route
                  path="admin/dashboard"
                  element={
                    <AdminDashboard
                      openModal={openModal}
                      role={role}
                      setIsOpenModal={setIsOpenModal}
                      referalValue={referalValue}
                      setReferalValue={setReferalValue}
                      currentLang={currentLang}
                      setCurrentLang={setCurrentLang}
                    />
                  }
                />
              </>
            ) : (
              <>
                <Route
                  path="/dashboard"
                  element={
                    <Dashboard
                      currentLang={currentLang}
                      setCurrentLang={setCurrentLang}
                    />
                  }
                />
                <Route
                  path="/admin/dashboard"
                  element={<Navigate replace to="/dashboard" />}
                />
              </>
            )}

            {/* ttttmmmmmpppp <Route path="admin/dashboard" element={<AdminDashboard />} /> */}
            <Route
              path="admin/finances"
              element={
                <AdminFinances
                  openModal={openModal}
                  role={role}
                  setIsOpenModal={setIsOpenModal}
                  referalValue={referalValue}
                  setReferalValue={setReferalValue}
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="admin/head-statistics"
              element={
                <AdminStatistics
                  title={t("Head of Sales Statistics")}
                  role={role}
                  openModal={openModal}
                  setIsOpenModal={setIsOpenModal}
                  referalValue={referalValue}
                  setReferalValue={setReferalValue}
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="admin/sales-statistics"
              element={
                <AdminStatistics
                  // title={t("Sales People Statistics")}
                  title={
                    role === "HEAD_OF_SALES"
                      ? t("Statistics The Head of sales")
                      : t("Sales People Statistics")
                  }
                  role={role}
                  openModal={openModal}
                  setIsOpenModal={setIsOpenModal}
                  referalValue={referalValue}
                  setReferalValue={setReferalValue}
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="admin/tokens-purchased"
              element={
                <AdminTokens
                  role={role}
                  openModal={openModal}
                  setIsOpenModal={setIsOpenModal}
                  referalValue={referalValue}
                  setReferalValue={setReferalValue}
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
            <Route
              path="admin/sales/tokens-purchased"
              element={
                <AdminTokens
                  role={role}
                  openModal={openModal}
                  setIsOpenModal={setIsOpenModal}
                  referalValue={referalValue}
                  setReferalValue={setReferalValue}
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />

            <Route
              path="admin/documents"
              element={
                <AdminDocuments
                  openModal={openModal}
                  role={role}
                  setIsOpenModal={setIsOpenModal}
                  referalValue={referalValue}
                  setReferalValue={setReferalValue}
                  currentLang={currentLang}
                  setCurrentLang={setCurrentLang}
                />
              }
            />
          </>
        ) : null}
      </Routes>
    </>
  );
}

export default App;
