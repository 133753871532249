import React, { useState, useEffect, useContext } from "react";
import styles from "./Main.module.scss";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import account from "../../../../img/account.png";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import ResetPassModal from "../ResetPassModal/ResetPassModal";
import axios from "axios";
import { API_URL } from "../../../../http";
import Cross from "../../../../img/IconComponents/Cross";
import Check from "../../../../img/IconComponents/Check";
import { checkSwift } from "../../../../helpers/validation";
import Triangle from "../../../../img/IconComponents/Triangle";
import {
  checkEmail,
  checkFirstName,
  checkLastName,
  checkPhone,
  checkAccountOwner,
  checkCity,
  checkStreet,
  checkPost,
  checkDoor,
  checkCountry,
} from "../../../../helpers/validation";
import icon2fa from "../../../../img/2fa.svg";
import postRequest from "../../../../helpers/postRequest";
import cn from "classnames";
import bell from "../../../../img/bell.svg";
import Notification from "../../../../components/Notification/Notification";
import key from "../../../../img/key.svg";
import lock from "../../../../img/lock.svg";
import { useTranslation } from "react-i18next";
import { checkIban } from "../../../../helpers/validation";
import PhoneCountrySelect from "../../../../components/PhoneCountrySelect/PhoneCountrySelect";
import { Context } from "../../../..";
import { useObserver } from "mobx-react-lite";
import { useNavigate } from "react-router";
import getRequest from "../../../../helpers/getRequest";
import moment from "moment";

const Main = () => {
  const [passReset, setPassReset] = useState(false);
  const [twoFactor, setTwoFactor] = useState(false);
  //User
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postCode, setPostCode] = useState("");
  const [street, setStreet] = useState("");
  const [accountOwner, setAccountOwner] = useState("");
  const [iban, setIban] = useState("");
  const [swift, setSwift] = useState("");
  const [errorIban, setErrorIban] = useState("");
  const [emailInitial, setEmailInitial] = useState("");
  const [phoneInitial, setPhoneInitial] = useState("");
  const [firstNameInitial, setFirstNameInitial] = useState("");
  const [lastNameInitial, setLastNameInitial] = useState("");
  const [companyInitial, setCompanyInitial] = useState("");
  const [cityInitial, setCityInitial] = useState("");
  const [countryInitial, setCountryInitial] = useState("");
  const [postCodeInitial, setPostCodeInitial] = useState("");
  const [streetInitial, setStreetInitial] = useState("");
  const [accountOwnerInitial, setAccountOwnerInitial] = useState("");
  const [ibanInitial, setIbanInitial] = useState("");
  const [swiftInitial, setSwiftInitial] = useState("");
  const [modal, setIsModal] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [newProfilePhoto, setNewProfilePhoto] = useState(null);
  const [profilPhotoName, setProfilPhotoName] = useState(null);
  const [pendingIban, setPendingIban] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorAccountOwner, setErrorAccountOwner] = useState("");
  const [errorSwift, setErrorSwift] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorStreet, setErrorStreet] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [errorHouseNumber, setErrorHouseNumber] = useState("");
  const [errorPostcode, setErrorPostcode] = useState("");
  const [appartment, setApartment] = useState("");
  const [appartmentInitial, setApartmentInitial] = useState("");
  const [modal2fa, setModal2fa] = useState(false);
  const [step2fa, setStep2fa] = useState(1);
  const [code2fa, setCode2fa] = useState("");
  const [loaderStep, setLoaderStep] = useState(false);
  const [codeError, setCodeError] = useState("");
  const [is2fa, setIs2fa] = useState(false);
  const { t } = useTranslation();
  const [currentLang, setCurrentLang] = useState(t("Germany"));
  const [countryCode, setCountryCode] = useState("+49");
  const [currentLangInitial, setCurrentLangInitial] = useState(t("Germany"));
  const [countryCodeInitial, setCountryCodeInitial] = useState("+49");
  const [isVerified, setIsVerified] = useState(true);
  const [isChangedModal, setIsChangedModal] = useState(false);
  const [notificationsInitial, setNotificationsInitial] = useState({});
  const [notifications, setNotifications] = useState({
    statusChange: false,
    purchaseOfPlant: false,
    harvestSale: false,
    requestPayment: false,
  });
  const [dob, setDob] = useState(null);
  const [initialDob, setInitialDob] = useState(null);
  const [dobError, setDobError] = useState(null);

  const [dayDob, setDayDob] = useState(moment(dob).year() || "");
  const [monthDob, setMonthDob] = useState("");
  const [yearDob, setYearDob] = useState("");
  const [totalDob, setTotalDob] = useState();

  const [vat, setVat] = useState("");
  const [initialVat, setInitialVat] = useState("");

  const navigate = useNavigate();

  const { store } = useContext(Context);

  const { destinationPath } = useObserver(() => ({
    destinationPath: store.destinationPath,
  }));

  const handleSwitch = (name) => {
    setNotifications((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const getSettings = () => {
    getRequest("v1/auth/whoami")
      .then((response) => {
        if (response.status === 200) {
          const { is_verified, is_2fa_enabled } = response.data;
          setIs2fa(is_2fa_enabled);
          setIsVerified(is_verified);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getRequest("v1/user/payment-details")
      .then((response) => {
        if (response.status === 200) {
          const { bic_swift, iban, account_owner_name } = response.data;
          setAccountOwner(account_owner_name || "");
          setSwift(bic_swift || "");
          setIban(iban || "");
          setSwiftInitial(bic_swift || "");
          setIbanInitial(iban || "");
          setAccountOwnerInitial(account_owner_name || "");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getRequest("v1/user/billing-details").then((response) => {
      if (response.data?.phone_number) {
        const countryCode1 = response.data?.phone_number?.slice(0, 4);
        const countryCode2 = response.data?.phone_number?.slice(0, 3);
        const phoneCountryName1 = getCountryName(countryCode1);
        const phoneCountryName2 = getCountryName(countryCode2);
        if (phoneCountryName1) {
          setCountryCode(countryCode1);
          setCurrentLang(phoneCountryName1);
          setPhone(response.data?.phone_number?.slice(4));
          setCountryCodeInitial(countryCode1);
          setCurrentLangInitial(phoneCountryName1);
          setPhoneInitial(response.data?.phone_number?.slice(4));
        } else if (phoneCountryName2) {
          setCountryCode(countryCode2);
          setCurrentLang(phoneCountryName2);
          setPhone(response.data?.phone_number?.slice(3));
          setCountryCodeInitial(countryCode2);
          setCurrentLangInitial(phoneCountryName2);
          setPhoneInitial(response.data?.phone_number?.slice(3));
        } else {
          setPhone("");
          setPhoneInitial("");
        }
      }
      setEmail(response.data?.email);
      setDob(response.data?.date_of_birth || "");
      setFirstName(response.data?.first_name || "");
      setLastName(response.data?.last_name || "");
      setCompany(response.data?.company_name || "");
      setCity(response.data?.city || "");
      setCountry(response.data?.country || "");
      setVat(response.data?.vat || "");
      setPendingIban(response.data?.pending_iban || "");
      setPostCode(response.data?.postcode || "");
      setStreet(response.data?.street || "");
      setInitialVat(response.data?.vat || "");
      setApartment(response.data?.door_no || "");
      setEmailInitial(response.data?.email);
      setFirstNameInitial(response.data?.first_name || "");
      setLastNameInitial(response.data?.last_name || "");
      setCompanyInitial(response.data?.company_name || "");
      setCityInitial(response.data?.city || "");
      setCountryInitial(response.data?.country || "");
      setPostCodeInitial(response.data?.postcode || "");
      setStreetInitial(response.data?.street || "");
      setApartmentInitial(response.data?.door_no || "");
      setInitialDob(response.data?.date_of_birth || "");
    });

    getRequest("v1/user/notifications-settings").then((response) => {
      const {
        harvest_sale_notifications,
        plant_purchase_notifications,
        request_for_payment_notifications,
        status_change_notifications,
      } = response.data;

      setNotifications({
        statusChange: harvest_sale_notifications,
        purchaseOfPlant: plant_purchase_notifications,
        harvestSale: request_for_payment_notifications,
        requestPayment: status_change_notifications,
      });
      setNotificationsInitial({
        statusChange: harvest_sale_notifications,
        purchaseOfPlant: plant_purchase_notifications,
        harvestSale: request_for_payment_notifications,
        requestPayment: status_change_notifications,
      });
    });

    getRequest("v1/local-files/profile-picture", true).then((response) => {
      setProfilePhoto(response.data);
    });
  };

  const checkFields = () => {
    const isError = [];
    checkFirstName(
      firstName,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorFirstName(value);
      }
    );

    checkCity(
      city,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorCity(value);
      }
    );

    checkCountry(
      country,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorCountry(value);
      }
    );

    if (!dayDob || !monthDob || !yearDob) {
      if (localStorage.getItem("i18n") != "EN") {
        setDobError("Geburtsdatum eingeben");
      } else {
        setDobError("Enter date of birth");
      }
      return;
    } else {
      setDobError("");
    }

    let totaldob = `${yearDob}-${monthDob}-${dayDob}`;

    const dateOfBirth = moment(totaldob);
    const today = moment();
    const ageCheck = today.diff(dateOfBirth, "years");

    if (ageCheck >= 18 && ageCheck < 100) {
      setDobError("");
      isError.push(Boolean(false));
    } else {
      if (localStorage.getItem("i18n") != "EN") {
        setDobError(
          "Sie müssen über 18 Jahre alt sein, um sich auf der Plattform zu registrieren"
        );
      } else {
        setDobError(
          "You must be over 18 years old to register on the platform"
        );
      }
      isError.push(Boolean(true));
    }

    // checkDOB(
    //   dob,
    //   () => {},
    //   (value) => {
    //     isError.push(Boolean(value))
    //     setDobError(value)
    //   },
    // )

    checkStreet(
      street,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorStreet(value);
      }
    );
    checkDoor(
      appartment,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorHouseNumber(value);
      }
    );
    checkPost(
      postCode,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorPostcode(value);
      }
    );

    checkLastName(
      lastName,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorLastName(value);
      }
    );
    checkIban(
      iban,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorIban(value);
      }
    );
    checkSwift(
      swift,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorSwift(value);
      }
    );

    checkEmail(
      email,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorEmail(value);
      }
    );
    checkPhone(
      `${countryCode}${phone}`,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorPhone(value);
      }
    );
    return isError.some((el) => el);
  };

  const submitNewData = () => {
    if (checkFields() || !isChanged) return;
    postRequest("v1/user/settings", {
      billingDetails: {
        first_name: firstName,
        last_name: lastName,
        company_name: company || null,
        street: street,
        door_no: appartment,
        postcode: postCode,
        city: city,
        country: country,
        date_of_birth: totalDob ? totalDob : new Date(dob).toISOString(),
        vat: vat,
      },
      bankAccountsForPayouts: {
        iban: iban,
        bic_swift: swift,
        account_owner_name: accountOwner,
      },
      accountDetails: {
        ...({ phone_number: `${countryCode}${phone}` }),
      },
      notificationsSettings: {
        status_change_notifications: notifications.statusChange,
        plant_purchase_notifications: notifications.purchaseOfPlant,
        harvest_sale_notifications: notifications.harvestSale,
        request_for_payment_notifications: notifications.requestPayment,
      },
    })
      .then(() => {
        setIsModal(true);
        getSettings();
        store.setIsSettingsChanged(false);
        setInterval(() => {
          setIsModal(false);
        }, 1500);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setErrorEmail("This email already exists");
        }
      });
    if (newProfilePhoto) {
      const formData = new FormData();
      formData.append("profile-picture", newProfilePhoto);
      // postRequest("v1/user/profile-picture", formData, 'multipart/form-data')
      axios({
        method: "post",
        url: `${API_URL}/v1/user/profile-picture`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        data: formData,
      });
    }
  };

  const handleDayChange = (event) => {
    const value = event.target.value;
    const regex = /^\d*$/;
    if (regex.test(value) && value.length <= 2 && value <= 31) {
      setDayDob(value);
    }
  };

  const handleInputBlur = (e, state, setter) => {
    if (state.length < 2) {
      setter("0" + state);
    }
  };

  const handleMonthChange = (event) => {
    const value = event.target.value;
    const regex = /^\d*$/;

    if (regex.test(value) && value.length <= 2 && value <= 12) {
      setMonthDob(value);
    }
  };

  const handleYearChange = (event) => {
    const value = event.target.value;
    const regex = /^\d{0,4}$/;

    if (regex.test(value)) {
      setYearDob(value);
    }
  };

  const start2fa = () => {
    setLoaderStep(true);
    postRequest(
      "v1/two-factor-auth/send-code",
      {},
      "application/json",
      localStorage.getItem("i18n").toLowerCase()
    )
      .then((response) => {
        setStep2fa(2);
        setLoaderStep(false);
      })
      .catch((error) => {
        setLoaderStep(false);
        if (error.response?.data?.statusCode === 400) {
          setStep2fa(2);
        }
      });
  };

  const handleImageName = (e, setterPhoto, setterName) => {
    setterPhoto(e.target?.files[0]);
    setterName(e.target?.files[0]?.name);
  };

  // const check2fa = () => {
  //   postRequest('v1/two-factor-auth/turn-on', { twoFactorCode: code2fa }, 'application/json', localStorage.getItem('i18n').toLowerCase())
  //     .then((response) => {
  //       setModal2fa(false);
  //       setIs2fa(true);
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       if (error.response?.data?.statusCode === 400) {
  //         setCodeError(t('Invalid code'))
  //       } else {
  //         setCodeError(t('Something went wrong'))
  //       }
  //     })
  // }

  const check2fa = () => {
    postRequest(
      "v1/two-factor-auth/turn-on",
      {
        twoFactorCode: code2fa,
        screen_height: window.innerHeight,
        screen_width: window.innerWidth,
        browser: navigator.userAgent,
      },
      "application/json",
      localStorage.getItem("i18n").toLowerCase()
    )
      .then((response) => {
        setModal2fa(false);
        setIs2fa(true);
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.status === 400) {
          setCodeError(t("Invalid code"));
        } else {
          setCodeError(t("Something went wrong"));
        }
      });
  };

  const handleRedirect = () => {
    const path = destinationPath;
    store.setDestinationPath(null);
    store.setIsSettingsChanged(false);
    navigate(path);
  };

  const getCountryName = (countryCode) => {
    switch (countryCode) {
      case "+43":
        return t("Austria");
      case "+32":
        return t("Belgium");
      case "+359":
        return t("Bulgaria");
      case "+385":
        return t("Croatia");
      case "+357":
        return t("Cyprus");
      case "+420":
        return t("Czech Republic");
      case "+45":
        return t("Denmark");
      case "+372":
        return t("Estonia");
      case "+358":
        return t("Finland");
      case "+33":
        return t("France");
      case "+49":
        return t("Germany");
      case "+30":
        return t("Greece");
      case "+36":
        return t("Hungary");
      case "+353":
        return t("Ireland");
      case "+39":
        return t("Italy");
      case "+371":
        return t("Latvia");
      case "+370":
        return t("Lithuania");
      case "+352":
        return t("Luxembourg");
      case "+356":
        return t("Malta");
      case "+31":
        return t("Netherlands");
      case "+48":
        return t("Poland");
      case "+351":
        return t("Portugal");
      case "+40":
        return t("Romania");
      case "+421":
        return t("Slovakia");
      case "+386":
        return t("Slovenia");
      case "+34":
        return t("Spain");
      case "+46":
        return t("Sweden");
      case "+41":
        return t("Switzerland");
      case "+44":
        return t("United Kingdom");
      default:
        return null;
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  const isChanged =
    email !== emailInitial ||
    phone !== phoneInitial ||
    firstName !== firstNameInitial ||
    lastName !== lastNameInitial ||
    dob !== totalDob ||
    company !== companyInitial ||
    city !== cityInitial ||
    country !== countryInitial ||
    postCode !== postCodeInitial ||
    street !== streetInitial ||
    appartment !== appartmentInitial ||
    accountOwner !== accountOwnerInitial ||
    iban !== ibanInitial ||
    swift !== swiftInitial ||
    notifications.harvestSale !== notificationsInitial.harvestSale ||
    notifications.purchaseOfPlant !== notificationsInitial.purchaseOfPlant ||
    notifications.requestPayment !== notificationsInitial.requestPayment ||
    notifications.statusChange !== notificationsInitial.statusChange ||
    vat !== initialVat ||
    Boolean(newProfilePhoto);

  useEffect(() => {
    store.setIsSettingsChanged(isChanged);
  }, [isChanged]);

  useEffect(() => {
    setIsChangedModal(destinationPath);
  }, [destinationPath]);

  useEffect(() => {
    if (!iban) {
      setIban(pendingIban);
    }
  }, [pendingIban]);

  useEffect(() => {
    if (dob) {
      setDayDob(moment(dob).date());
      const formattedDay =
        moment(dob).month() + 1 < 10
          ? `0${moment(dob).month() + 1}`
          : `${moment(dob).month() + 1}`;
      setMonthDob(formattedDay);
      setYearDob(moment(dob).year());
    }
  }, [dob]);

  useEffect(() => {
    if (dayDob > 0 && monthDob > 0 && yearDob > 0 && yearDob.length > 3) {
      const dob = `${dayDob}.${monthDob}.${yearDob}`;

      const minDate = moment().subtract(18, "years"); // Дата, предшествующая 18 годам от текущей даты
      const maxDate = moment().subtract(100, "years");
      const formattedInputDate = moment(dob, "DD.MM.YYYY"); // Преобразуем введенную дату в объект Moment
      const isValid =
        formattedInputDate.isBefore(minDate) &&
        !formattedInputDate.isBefore(maxDate);

      if (isValid == false) {
        if (localStorage.getItem("i18n") != "EN") {
          setDobError(
            "Sie müssen über 18 Jahre alt sein, um sich auf der Plattform zu registrieren"
          );
        } else {
          setDobError(
            "You must be over 18 years old to register on the platform"
          );
        }
      } else {
        setDobError("");
      }

      setTotalDob(moment(dob, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
    }
  }, [dayDob, monthDob, yearDob]);

  return (
    <>
      <section className={styles.settings}>
        <div className={styles.settings__account}>
          <SectionTitle text={t("Account Details")} />
          <div className={styles.settings__account_info}>
            <div className={styles.settings__account_info_pict}>
              <img
                className={styles.settings__account_info_photo}
                src={account}
                alt={t("account")}
              />
              {newProfilePhoto || (profilePhoto && profilePhoto.size) ? (
                <img
                  className={styles.settings__account_info_pict_uploaded}
                  src={URL.createObjectURL(newProfilePhoto || profilePhoto)}
                  alt=""
                />
              ) : (
                <img src={account} alt="account" />
              )}
              <label className={styles.settings__account_upload}>
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  onChange={(e) =>
                    handleImageName(e, setNewProfilePhoto, setProfilPhotoName)
                  }
                />
                <p className={styles.settings__account_upload_btn}>
                  {t("Upload Image")}
                </p>
              </label>
              <div>
                {profilPhotoName !== null ? (
                  <p className={styles.settings__account_upload_text}>
                    {profilPhotoName}
                  </p>
                ) : null}
              </div>
            </div>
            <div className={styles.settings__initials}>
              <div className={styles.settings__initials_inner}>
                <div className={styles.settings__initials_row}>
                  <Input
                    label={t("Email")}
                    type="email"
                    placeholder={t("Email")}
                    value={email}
                    onChange={(e) =>
                      checkEmail(e.target.value, setEmail, setErrorEmail)
                    }
                    error={errorEmail}
                    disabled={true}
                  />{" "}
                  <div></div>
                </div>
                <div className={styles.settings__initials_row}>
                  <div
                    className={cn(
                      styles.settings_dob,
                      dobError ? styles.settings_dob_error : ""
                    )}
                  >
                    <div className={styles.settings_dob_label}>
                      {t("Date of birth")}
                    </div>
                    <div
                      className={cn(
                        styles.settings__dob,
                        dobError && dobError.length
                          ? styles.settings__dob_error
                          : ""
                      )}
                    >
                      <input
                        type="number"
                        placeholder="DD"
                        className={styles.register__form_dob_input}
                        value={dayDob}
                        maxLength="2"
                        onChange={(e) => handleDayChange(e)}
                        onBlur={(e) => handleInputBlur(e, dayDob, setDayDob)}
                      />
                      <input
                        type="number"
                        placeholder="MM"
                        className={styles.register__form_dob_input}
                        value={monthDob}
                        maxLength="2"
                        onChange={(e) => handleMonthChange(e)}
                        onBlur={(e) =>
                          handleInputBlur(e, monthDob, setMonthDob)
                        }
                      />
                      <input
                        type="number"
                        placeholder="YYYY"
                        className={styles.register__form_dob_input}
                        value={yearDob !== null ? yearDob : ""}
                        onChange={(e) => handleYearChange(e)}
                        // onChange={checkYear(yearDob, handleYearChange, setDobError)}
                      />
                    </div>
                    {/* <DatePicker
                      value={dob}
                      type="settings"
                      datePickAction={(dob) =>
                        checkDOB(dob, setDob, setDobError)
                      }
                      placeholder={`${
                        localStorage.getItem('i18n') == 'DE' ? 'Ihr' : ''
                      } ${t('Date of birth')}`}
                    /> */}
                    {dobError ? (
                      <div className={styles.dob_error}>
                        <Triangle />
                        <div className={styles.dob_error_text}>
                          {t(dobError)}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className={styles.form_wrapper__phone}>
                    <div className={styles.form_wrapper__country}>
                      <PhoneCountrySelect
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        setCountryCode={setCountryCode}
                      />
                    </div>
                    <Input
                      label={t("Phone number")}
                      type="text"
                      name={t("phone")}
                      placeholder={countryCode}
                      value={`${countryCode}${phone}`}
                      onChange={(e) =>
                        checkPhone(
                          e.target.value,
                          (value) => setPhone(value.slice(countryCode.length)),
                          setErrorPhone
                        )
                      }
                      error={errorPhone}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.settings__inner}>
          <div className={styles.settings__billing}>
            <SectionTitle text={t("Billing Details")} />
            <div className={styles.settings__billing_inner}>
              <div className={styles.settings__billing_row}>
                <Input
                  label={t("First name")}
                  type="text"
                  placeholder={t("Name")}
                  value={firstName}
                  onChange={(e) =>
                    checkFirstName(
                      e.target.value,
                      setFirstName,
                      setErrorFirstName
                    )
                  }
                  error={errorFirstName}
                />
                <Input
                  label={t("Last name")}
                  type="text"
                  placeholder={t("Surname")}
                  value={lastName}
                  onChange={(e) =>
                    checkLastName(e.target.value, setLastName, setErrorLastName)
                  }
                  error={errorLastName}
                />
                <div></div>
              </div>
              <div className={styles.settings__billing_row}>
                <Input
                  label={t("Company Name (optional)")}
                  type="text"
                  placeholder={t("Company name")}
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
                <Input
                  label={t("Vat (optional)")}
                  type="text"
                  placeholder={t("VAT")}
                  value={vat}
                  onChange={(e) => setVat(e.target.value)}
                  className={
                    localStorage?.getItem("i18n") === "EN" ? "vat_en" : "vat_de"
                  }
                />
                <div></div>
              </div>
              <div className={styles.settings__billing_row}>
                <Input
                  label={t("Street")}
                  type="text"
                  placeholder={t("Street")}
                  value={street}
                  onChange={(e) =>
                    checkStreet(e.target.value, setStreet, setErrorStreet)
                  }
                  error={errorStreet}
                />
                <Input
                  label={t("House number")}
                  type="text"
                  placeholder="#"
                  value={appartment}
                  onChange={(e) =>
                    checkDoor(e.target.value, setApartment, setErrorHouseNumber)
                  }
                  error={errorHouseNumber}
                />
                <Input
                  label={t("Postcode")}
                  type="text"
                  onChange={(e) =>
                    checkPost(e.target.value, setPostCode, setErrorPostcode)
                  }
                  error={errorPostcode}
                  placeholder={t("Postcode")}
                  value={postCode}
                />
              </div>
              <div className={styles.settings__billing_row}>
                <Input
                  label={t("City")}
                  type="text"
                  placeholder={t("City")}
                  value={city}
                  onChange={(e) =>
                    checkCity(e.target.value, setCity, setErrorCity)
                  }
                  error={errorCity}
                />
                <Dropdown
                  value={country}
                  onChange={(country) =>
                    checkCountry(country, setCountry, setErrorCountry)
                  }
                  error={errorCountry}
                />
                <div></div>
              </div>
            </div>
          </div>
          <div className={styles.settings__bank}>
            <SectionTitle text={t("Bank Accounts for Payouts")} />
            <div className={styles.settings__bank_inner}>
              <Input
                label={t("Account Owner")}
                type="text"
                placeholder={t("Account Owner")}
                value={accountOwner}
                onChange={(e) =>
                  checkAccountOwner(
                    e.target.value,
                    setAccountOwner,
                    setErrorAccountOwner
                  )
                }
                error={errorAccountOwner}
              />
              <div></div>
            </div>
            <div className={styles.settings__bank_inner}>
              <Input
                label="IBAN"
                type="text"
                placeholder="IBAN"
                value={iban}
                onChange={(e) =>
                  checkIban(e.target.value, setIban, setErrorIban)
                }
                error={errorIban}
              />
              <Input
                label="BIC / SWIFT"
                type="text"
                placeholder="BIC / SWIFT"
                value={swift}
                onChange={(e) =>
                  checkSwift(e.target.value, setSwift, setErrorSwift)
                }
                error={errorSwift}
              />
            </div>
            {pendingIban ? (
              <Notification
                icon={bell}
                color="green"
                description={t(
                  "Your IBAN was saved and will be added here within 7 days."
                )}
              />
            ) : null}
          </div>
          <div className={styles.settings__security}>
            <SectionTitle text={t("Security")} />
            <div className={styles.settings__security_inner}>
              <div className={styles.settings__security_row}>
                <p>{t("Password")}</p>
                <Button
                  text={t("Change Password")}
                  icon={key}
                  onClick={() => setPassReset((prev) => !prev)}
                />
              </div>
              <div className={styles.settings__security_row}>
                <p>{t("2-Factor-Authentication")}</p>
                <Button
                  disabled={is2fa}
                  text={`${!is2fa ? t("Enable 2FA") : t("2FA enabled")}`}
                  icon={lock}
                  onClick={() => setModal2fa(true)}
                />
              </div>
            </div>
          </div>

          {/* <div className={styles.settings__notifications}>
            <SectionTitle text={t('Notifications')} />
            <div className={styles.settings__notifications_inner}>
              <div className={styles.settings__notifications_row}>
                <Switcher
                  value={notifications.statusChange}
                  onChange={() => handleSwitch('statusChange')}
                />
                <p>{t('Status change')}</p>
              </div>
              <div className={styles.settings__notifications_row}>
                <Switcher
                  value={notifications.purchaseOfPlant}
                  onChange={() => handleSwitch('purchaseOfPlant')}
                />
                <p>{t('Purchase of a token')}</p>
              </div>
              <div className={styles.settings__notifications_row}>
                <Switcher
                  value={notifications.harvestSale}
                  onChange={() => handleSwitch('harvestSale')}
                />
                <p>{t('Harvest sale')}</p>
              </div>
              <div className={styles.settings__notifications_row}>
                <Switcher
                  value={notifications.requestPayment}
                  onChange={() => handleSwitch('requestPayment')}
                />
                <p>{t('Request for payment')}</p>
              </div>
            </div>
          </div> */}
        </div>
        {errorEmail !== "" && <p className={styles.error}>{errorEmail}</p>}
      </section>
      <div className={styles.save}>
        <Button
          text={t("Save Changes")}
          color="green"
          onClick={() => submitNewData()}
        />
      </div>
      {modal ? (
        <div className={styles.modal__success}>
          <div className={styles.modal__success_inner}>
            <h2>{t("Settings changed successfully")}</h2>
            <div className={styles.modal__success_inner_check}>
              <Check />
            </div>
          </div>
        </div>
      ) : null}
      {isChangedModal !== false && isChangedModal !== null ? (
        <div className={styles.modal__success}>
          <div className={styles.modal__success_inner}>
            <button
              className={styles.modal__close}
              onClick={() => setIsChangedModal(false)}
            >
              <Cross />
            </button>
            <h2>{t("The changes were not saved. Proceed without saving?")}</h2>
            <div className={styles.modal__buttons}>
              <button onClick={handleRedirect}>{t("Yes")}</button>
              <button onClick={() => store.setDestinationPath(null)}>
                {t("No")}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {modal2fa ? (
        <div className={styles.modal2fa}>
          {step2fa === 1 ? (
            <div className={styles.modal2fa__inner}>
              {loaderStep ? (
                <div className={styles.modal2fa__loader}>
                  <div
                    className={cn(
                      styles.modal2fa__loader_dot,
                      styles.modal2fa__loader_dot1
                    )}
                  ></div>
                  <div
                    className={cn(
                      styles.modal2fa__loader_dot,
                      styles.modal2fa__loader_dot2
                    )}
                  ></div>
                </div>
              ) : null}
              <button
                className={styles.modal2fa__close}
                onClick={() => setModal2fa(false)}
              >
                <Cross />
              </button>
              <div className={styles.modal2fa__title}>
                {t("Enable 2-Factor-Authentication")}
              </div>
              <div className={styles.modal2fa__subtitle}>
                {t("Protect your account with 2-Step verification.")}
              </div>
              <img className={styles.modal2fa__img} src={icon2fa} alt="2fa" />
              <div className={styles.modal2fa__text}>
                {t(
                  "In addition to your password, 2-Step verification adds a quick"
                ) + " "}
                {t("Second step to verify that it’s you.")}
              </div>
              <button
                className={styles.modal2fa__start}
                onClick={() => start2fa()}
              >
                {t("Get Started")}
              </button>
            </div>
          ) : (
            <div className={styles.modal2fa__inner}>
              <button
                className={styles.modal2fa__close}
                onClick={() => setModal2fa(false)}
              >
                <Cross />
              </button>
              <div
                className={cn(
                  styles.modal2fa__title,
                  styles.modal2fa__title_offset
                )}
              >
                {t("Enter code from your mail")}
              </div>
              <Input
                label={t("Enter code")}
                type="text"
                placeholder="code"
                value={code2fa}
                onChange={(e) => setCode2fa(e.target.value)}
              />
              {codeError && codeError.length ? (
                <div className={styles.modal2fa__error}>{codeError}</div>
              ) : null}
              <div
                onClick={() => check2fa()}
                className={styles.modal2fa__start}
              >
                {t("Submit")}
              </div>
            </div>
          )}
        </div>
      ) : null}
      {passReset && <ResetPassModal close={setPassReset} />}
      {/* {twoFactor && <Modal type="twoFactor"/> } */}
    </>
  );
};

export default Main;
