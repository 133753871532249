/** @format */

import React, {
  useState,
  useEffect,
} from 'react';
import styles from './Main.module.scss';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import AdminCommision from '../../../components/AdminCommision/AdminCommision';
import AdminBlock from '../../../components/AdminBlock/AdminBlock';
import cn from 'classnames';
import TableElement from './TableElement/TableElement';
import axios from 'axios';
import { API_URL } from '../../../http';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader/Loader';
import { useTranslation } from 'react-i18next';
import getRequest from '../../../helpers/getRequest';
import Pagination from '../../../components/Pagination/Pagination';
import Chart from './Chart/Chart';
import DatePicker from './DatePicker/DatePicker';
import moment from 'moment';
import Select from '../../../components/Select/Select';

import { FRONT_URL } from '../../../http';
import { copyText } from '../../../helpers/copyText';
import copyIcon from '../../../img/copy.svg';
import Button from '../../../components/Button/Button';
import HouseIcon from '../../../img/house.svg';
import postRequest from '../../../helpers/postRequest';
import TabeleElementSale from './TableElementSale/TableElementSale';
const Main = ({
  role,
  salesLevel,
  currentLang,
  isOpenModal,
}) => {
  const [
    myCurrentCommision,
    setMyCurrentCommision,
  ] = useState(0);
  const [myCurrentBalance, setMyCurrentBalance] =
    useState(0.0);
  const [myPendingBalance, setMyPendingBalance] =
    useState(0.0);
  const [myTotalCommision, setMyTotalCommision] =
    useState(0.0);
  const [vat, setVat] = useState(0);

  const [headCommision, setHeadCommision] =
    useState(0);
  const [headRegistred, setHeadRegistred] =
    useState(0);
  const [
    headTotalCommision,
    setHeadTotalCommision,
  ] = useState(0);

  const [salesCommision, setSalesCommision] =
    useState(0);
  const [salesRegistred, setSalesRegistred] =
    useState(0);
  const [
    salesTotalCommision,
    setSalesTotalCommision,
  ] = useState(0);

  const [clientAmount, setClientAmount] =
    useState(0);
  const [clientPlants, setClientPlants] =
    useState(0);
  const [clientCost, setClientCost] = useState(0);

  const [totalSales, setTotalSales] = useState(0);
  const [sales, setSales] = useState(null);
  const [salesTotal, setSalesTotal] =
    useState(10);

  const [totalTokenSold, setTotalTokenSold] =
    useState(0);
  const [
    totalTokenSoldPrice,
    setTotalTokenSoldPrice,
  ] = useState(0);

  const [clients, setClients] = useState(null);
  const [commissions, setCommissions] = useState({
    2: null,
    3: null,
    4: null,
  });

  const [isLoad, setIsLoad] = useState(false);

  const [createSaleLink, setCreateSaleLink] =
    useState('');
  const [createClientLink, setCreateClientLink] =
    useState('');

  const [pricePerPlant, setPricePerPlant] =
    useState(null);
  const [page, setPage] = useState(1);
  const [selectedMonth, setSelectedMonth] =
    useState(
      moment().format(
        'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
      ),
    );
  const [pageForSales, setPageForSales] =
    useState(1);

  const [linkCopied, setLinkCopied] = useState();

  const [code, setCode] = useState('');

  const [hs, setHs] = useState([
    { label: 'All', value: 'all' },
  ]);

  const [selectedOption, setSelectedOption] =
    useState({
      label: 'All',
      value: 'all',
    });

  /* for sales level 1 from tomas hack */

  const [refClients, setRefClients] = useState(
    [],
  );
  const [refClientsPage, setRefClientsPage] =
    useState(1);
  const [totalRefClients, setTotalRefClients] =
    useState(0);

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCopySale = () => {
    copyText(createSaleLink);

    setLinkCopied(true);
    const timer = setTimeout(() => {
      setLinkCopied(false);
    }, [2000]);

    return () => {
      clearTimeout(timer);
    };
  };

  const handleCopyClient = () => {
    copyText(createClientLink);

    setLinkCopied(true);
    const timer = setTimeout(() => {
      setLinkCopied(false);
    }, [2000]);

    return () => {
      clearTimeout(timer);
    };
  };

  //check if user has calculator visible
  useEffect(() => {
    getRequest('v1/auth/whoami')
      .then((res) => {
        if (res.data.isCalculatorShown === true) {
          localStorage.setItem(
            'isCalcShown',
            true,
          );
        } else {
          localStorage.removeItem('isCalcShown');
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getRequest('v1/constants/plant/price')
      .then((response) =>
        setPricePerPlant(response.data),
      )
      .catch((error) => console.log(error));

    getRequest('v1/constants/vat')
      .then((response) =>
        setVat(response.data.data),
      )
      .catch((error) => console.log(error));

    if (role == 'MAIN_SALE') {
      getRequest(
        'v1/statistics/head-of-sales',
      ).then((response) => {
        console.log(response);
        setHs([{ label: 'All', value: 'all' }]);
        response.data?.data?.map((item) => {
          setHs((prev) => [
            ...prev,
            {
              label: `${item?.first_name} ${item?.last_name}`,
              value: item.id,
            },
          ]);
        });
        // Dont remove its for hotfix
        // setHs(response.data?.data?.map(item => {
        //   return(
        //     {label: `${item?.first_name} ${item?.last_name}`, value: item?.id}
        //   )
        // }))
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    setIsLoad(true);
    if (role === 'ADMIN' || role === 'CLIENT') {
      navigate('/');
    }
    if (role === 'MAIN_SALE') {
      getRequest(
        'v1/statistics/dashboard/upper-level',
      )
        .then((response) => {
          setMyCurrentCommision(
            response.data?.my_com_rate,
          );
          setMyCurrentBalance(
            response.data?.my_current_commission,
          );
          setMyPendingBalance(
            response.data?.my_pending_commission,
          );
          setMyTotalCommision(
            response.data?.my_total_commission,
          );

          setHeadCommision(
            response.data?.hs_rate,
          );
          setHeadRegistred(
            response.data?.head_sale_count,
          );
          setHeadTotalCommision(
            response.data?.hs_total_commission,
          );

          setSalesCommision(
            response.data?.sale_rate,
          );
          setSalesRegistred(
            response.data?.sale_count,
          );
          setSalesTotalCommision(
            response.data?.sale_commission,
          );

          setClientAmount(
            response.data?.clientsData?.users,
          );
          setClientPlants(
            response.data?.clientsData?.plants,
          );
          setClientCost(
            response.data?.clientsData?.amount,
          );
          setIsLoad(false);
        })
        .catch((err) => {
          setIsLoad(false);
          console.log(err);
        });

      getRequest(
        'v1/statistics/dashboard/upper-level/clients',
      )
        .then((response) => {
          // setClientAmount
          setIsLoad(false);
          setClients(response.data?.[1]);
        })
        .catch((error) => {
          setIsLoad(false);
          console.log(error);
        });
    }
    if (role === 'HEAD_OF_SALES') {
      getRequest(
        'v1/statistics/dashboard/head-of-sale',
      )
        .then((response) => {
          setMyCurrentCommision(
            response.data?.my_com_rate.rate,
          );
          setMyCurrentBalance(
            response.data?.my_current_commission,
          );
          setMyPendingBalance(
            response.data?.my_pending_commission,
          );
          setMyTotalCommision(
            response.data?.my_total_commission,
          );

          setSalesCommision(
            response.data?.sale_rate,
          );
          setSalesRegistred(
            response.data?.sale_count,
          );
          setSalesTotalCommision(
            response.data?.sale_commission,
          );

          setClientAmount(
            response.data?.clientsData?.users,
          );
          setClientPlants(
            response.data?.clientsData?.plants,
          );
          setClientCost(
            response.data?.clientsData?.amount,
          );
          setIsLoad(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoad(false);
        });
      postRequest('v1/statistics/sales/levels', {
        page: pageForSales,
        perPage: 10,
      })
        .then((res) => {
          setTotalSales(res.data?.total);
          setSales(res.data?.data);
          setSalesTotal(res?.data?.total || 0);
        })
        .catch((err) => console.log(err));
    }
    if (role === 'SALE') {
      getRequest('v1/statistics/dashboard/sale')
        .then((response) => {
          setMyCurrentCommision(
            response.data
              ?.my_direct_sale_commission,
          );
          setMyCurrentBalance(
            response.data?.my_current_commission,
          );
          setMyPendingBalance(
            response.data?.my_pending_commission,
          );
          setMyTotalCommision(
            response.data?.my_total_commission,
          );
          setClientAmount(
            response.data?.clientsData?.users,
          );
          setClientPlants(
            response.data?.clientsData?.plants,
          );
          setClientCost(
            response.data?.clientsData?.amount,
          );
          setTotalSales(
            +response.data
              ?.sales_recruited_directly +
              +response.data
                ?.sales_recruited_by_referrals,
          );
          setTotalTokenSold(
            response.data
              ?.tokens_sold_by_referrals,
          );
          setTotalTokenSoldPrice(
            response.data
              ?.amount_sold_by_referrals,
          );
          setCommissions({
            2: response.data
              ?.from_sale2_commission,
            3: response.data
              ?.from_sale3_commission,
            4: response.data
              ?.from_sale4_commission,
          });
          setIsLoad(false);
        })
        .catch((error) => {
          setIsLoad(false);
          console.log(error);
          console.log(
            'Something sent wrong, try to relogin',
          );
        });

      postRequest('v1/statistics/sales/levels', {
        page: pageForSales,
        perPage: 10,
      })
        .then((res) => {
          console.log(res.data?.data);
          setSales(res.data?.data);
          setSalesTotal(res?.data?.total || 0);
        })
        .catch((err) => console.log(err));

      getRequest(
        'v1/statistics/dashboard/sale/clients',
      )
        .then((response) => {
          setIsLoad(false);
          setClients(response.data?.[1]);
        })
        .catch((error) => {
          setIsLoad(false);
          console.log(
            'Something sent wrong, try to relogin',
          );
        });
    }
  }, [role, pageForSales]);

  useEffect(() => {
    if (role === 'MAIN_SALE') {
      getRequest(
        `v1/statistics/dashboard/upper-level/clients?page=${page}&take=10`,
      )
        .then((response) => {
          // setClientAmount
          setIsLoad(false);
          setClients(response.data?.[1]);
        })
        .catch((error) => {
          setIsLoad(false);
          console.log(error);
        });
    }
    if (role === 'HEAD_OF_SALES') {
      getRequest(
        `v1/statistics/dashboard/head-of-sale/clients?page=${page}&take=10`,
      )
        .then((response) => {
          setIsLoad(false);
          setClients(response?.data[1]);
        })
        .catch((error) => {
          setIsLoad(false);
          console.log(error);
        });
    }
    if (role === 'SALE') {
      getRequest(
        `v1/statistics/dashboard/sale/clients?page=${page}&take=10`,
      )
        .then((response) => {
          setIsLoad(false);
          setClients(response.data?.[1]);
        })
        .catch((error) => {
          setIsLoad(false);
          console.log(
            'Something sent wrong, try to relogin',
          );
        });
    }
  }, [page, role]);

  useEffect(() => {
    if (role === 'MAIN_SALE') {
      setHs([{ label: 'All', value: 'all' }]);

      let name =
        selectedOption.label.split(' ')[0];
      let lastName =
        selectedOption.label.split(' ')[1];
      const url = `v1/statistics/dashboard/upper-level/clients?page=${page}&take=10${
        name !== 'All'
          ? `&hs_first_name=${name}&hs_last_name=${lastName}`
          : ''
      }`;
      getRequest(url)
        .then((response) => {
          setIsLoad(false);
          setClients(response.data?.[1]);
        })
        .catch((error) => {
          setIsLoad(false);
          console.log(error);
        });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (role === 'SALE') {
      getRequest('v1/referrals/my-code')
        .then((response) => {
          setCreateSaleLink(
            response.data.invite_link,
          );
          setCreateClientLink(
            response.data.invite_client_link,
          );
        })
        .catch((error) => console.log(error));
    }
    if (role === 'HEAD_OF_SALES') {
      postRequest('v1/referral-invites')
        .then((response) => {
          setCreateSaleLink(
            response.data.invite_link,
          );
          // setCreateClientLink(response.data.invite_client_link);
        })
        .catch((error) => console.log(error));
    }
  }, [role]);

  useEffect(() => {
    if (
      salesLevel === 1 &&
      localStorage.getItem('isCalcShown')
    ) {
      const getClentsReferFriends = async () => {
        const response = await postRequest(
          'v1/statistics/sale/clients-referrals/under-head',
          {
            page: refClientsPage,
            perPage: 10,
          },
        );
        const data = await response.data;
        setRefClients(data.data);
        setTotalRefClients(data.total);
      };

      getClentsReferFriends();
    }
  }, [refClientsPage, salesLevel]);

  return (
    <section className={styles.main}>
      <Button
        to='/'
        text='To Home Page'
        color='home'
        icon={HouseIcon}
      />
      {role === 'SALE' ||
      role === 'HEAD_OF_SALES' ? (
        <>
          {createClientLink && (
            <div
              className={styles.main__link_copy}>
              <div
                className={
                  styles.main__link_copy_wrapper
                }>
                <div
                  className={
                    styles.main__link_copy_title
                  }>
                  {t('Invite Clients')}:
                </div>
                <button
                  className={
                    styles.main__link_copy_btn
                  }
                  onClick={() =>
                    handleCopyClient()
                  }>
                  <img
                    src={copyIcon}
                    alt='copy'
                  />
                  <div
                    className={cn(
                      styles.main__link_copy_status,
                      linkCopied
                        ? styles.main__link_copy_status_active
                        : null,
                    )}>
                    {t('Copied')}
                  </div>
                </button>
              </div>
              <div
                className={
                  styles.main__link_copy_link
                }>
                {createClientLink}
              </div>
            </div>
          )}

          {createSaleLink && (
            <div
              className={styles.main__link_copy}>
              <div
                className={
                  styles.main__link_copy_wrapper
                }>
                <div
                  className={
                    styles.main__link_copy_title
                  }>
                  {t('Invite Sales')}:
                </div>
                <button
                  className={
                    styles.main__link_copy_btn
                  }
                  onClick={() =>
                    handleCopySale()
                  }>
                  <img
                    src={copyIcon}
                    alt='copy'
                  />
                  <div
                    className={cn(
                      styles.main__link_copy_status,
                      linkCopied
                        ? styles.main__link_copy_status_active
                        : null,
                    )}>
                    {t('Copied')}
                  </div>
                </button>
              </div>
              <div
                className={
                  styles.main__link_copy_link
                }>
                {createSaleLink}
              </div>
            </div>
          )}
        </>
      ) : null}
      <div className={styles.main__subheader}>
        {['HEAD_OF_SALES', 'SALE'].includes(
          role,
        ) ? (
          <SectionTitle text={t('My Balance ')} />
        ) : (
          <SectionTitle text={t('My Balance')} />
        )}
        {role === 'HEAD_OF_SALES' ? (
          <AdminCommision
            text={t('My commission')}
            percent={myCurrentCommision}
            tooltip={false}
          />
        ) : (
          <AdminCommision
            text={t('My commission')}
            percent={myCurrentCommision}
            tooltip={false}
          />
        )}
      </div>

      <div className={styles.main__wrapper_info}>
        <div
          className={
            styles.main__wrapper_info_block
          }>
          {isLoad ? (
            <Loader />
          ) : (
            <AdminBlock
              title={
                [
                  'HEAD_OF_SALES',
                  'SALE',
                ].includes(role)
                  ? t('Pending Commission')
                  : t('Pending Balance')
              }
              value={myPendingBalance}
              subValue='EUR'
            />
          )}
          {isLoad ? (
            <Loader />
          ) : (
            <AdminBlock
              title={
                [
                  'HEAD_OF_SALES',
                  'SALE',
                ].includes(role)
                  ? t('Confirmed Commission')
                  : localStorage.getItem(
                      'i18n',
                    ) != 'EN'
                  ? t('Meine Provision')
                  : t('Current Balance')
              }
              value={myCurrentBalance}
              subValue='EUR'
            />
          )}

          {isLoad ? (
            <Loader />
          ) : (
            <AdminBlock
              title={
                [
                  'HEAD_OF_SALES',
                  'SALE',
                ].includes(role)
                  ? t(
                      'Total Turnover (Pending Commission+Confirmed Commission)',
                    )
                  : t('Total commission')
              }
              value={myTotalCommision}
              subValue='EUR'
            />
          )}
        </div>
        <div className={styles.chart__wrapper}>
          <div className={styles.chart__header}>
            <div className={styles.chart__title}>
              {t('Sold Tokens')}
            </div>
            <DatePicker
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
          </div>
          <div className={styles.chart}>
            <Chart
              selectedMonth={selectedMonth}
              role={role}
            />
          </div>
        </div>
      </div>

      {role.length &&
      role !== 'HEAD_OF_SALES' &&
      role !== 'SALE' ? (
        <>
          <div className={styles.main__subheader}>
            <SectionTitle
              text={t('Head of Sales Statistics')}
            />
            <div></div>
            {/* <AdminCommision
              text={t("Head of Sales commission")}
              percent={headCommision}
            /> */}
          </div>
          <div className={styles.main__wrapper}>
            {isLoad ? (
              <Loader />
            ) : (
              <AdminBlock
                title={
                  localStorage.getItem('i18n') ===
                  'DE'
                    ? 'Gesamtzahl der registrierten Tippgeber'
                    : 'Total head of sales registered'
                }
                value={headRegistred}
                linkText={t(
                  'open detailed statistics',
                )}
                link='/admin/head-statistics'
                type='int'
              />
            )}
            {isLoad ? (
              <Loader />
            ) : (
              <div></div>
              // <AdminBlock
              //   title={t("Total commission")}
              //   value={headTotalCommision}
              //   subValue="EUR"
              // />
            )}
            <div
              className={
                styles.main__dummy
              }></div>
          </div>
        </>
      ) : null}
      {/* {role.length && role !== "SALE" ? (
        <>
          <div className={styles.main__subheader}>
            {role === "HEAD_OF_SALES" ? (
              <SectionTitle text={t("Sales People Head of sales")} />
            ) : (
              <SectionTitle text={t("Sales People Statistics")} />
            )}
            {role === "HEAD_OF_SALES" ? (
              <div></div>
            ) : (
              <AdminCommision
                text={t("Sales commission")}
                percent={salesCommision}
              />
            )}
          </div>
          <div className={styles.main__wrapper}>
            {isLoad ? (
              <Loader />
            ) : (
              <AdminBlock
                type="int"
                // title={t("Total sales registered")}
                title={
                  role === "HEAD_OF_SALES"
                    ? t("Total sales registered Head of sales")
                    : t("Total sales registered")
                }
                value={salesRegistred}
                linkText={t("open detailed statistics")}
                link="/admin/sales-statistics"
              />
            )}
            {isLoad ? (
              <Loader />
            ) : (
              <>
                {role === "HEAD_OF_SALES" ? (
                  <div></div>
                ) : (
                  <AdminBlock
                    title={t("Total commission")}
                    value={salesTotalCommision}
                    subValue="EUR"
                  />
                )}
              </>
            )}
            <div className={styles.main__dummy}></div>
          </div>
        </>
      ) : null} */}
      {role !== 'HEAD_OF_SALES' && (
        <>
          <div className={styles.main__subheader}>
            <SectionTitle
              text={t('Clients Statistics')}
            />
          </div>
          <div className={styles.main__wrapper}>
            {isLoad ? (
              <Loader />
            ) : (
              <AdminBlock
                title={t(
                  'Total clients registered',
                )}
                value={clientAmount}
                type='int'
              />
            )}
            {isLoad ? (
              <Loader />
            ) : (
              <AdminBlock
                title={t(
                  'Total tokens purchased',
                )}
                value={clientPlants}
                type='int'
                linkText={t(
                  'open detailed statistics',
                )}
                link='/admin/tokens-purchased'
              />
            )}
            {isLoad ? (
              <Loader />
            ) : (
              <AdminBlock
                title={t('Total tokens cost')}
                value={clientCost}
                subValue='EUR'
              />
            )}
          </div>
        </>
      )}
      {clients &&
      clients.length &&
      role !== 'HEAD_OF_SALES' ? (
        <>
          <div
            className={styles.main__table_wrap}>
            <div
              className={cn(
                styles.main__subheader,
                styles.main__table_section,
              )}>
              <SectionTitle
                text={t(
                  'Detailed Clients Statistics',
                )}
              />
            </div>
            {role && role === 'MAIN_SALE' && (
              <Select
                options={hs}
                value={selectedOption}
                onChange={handleChange}
                placeholder={t('Select')}
                width='200px'
              />
            )}
          </div>
          <div className={styles.main__table}>
            <div
              className={cn(
                styles.main__table_row,
                styles.main__table_header,
              )}>
              <div
                className={
                  styles.main__table_client
                }>
                {t('Client Name')}
              </div>
              <div
                className={
                  styles.main__table_plants
                }>
                {t('Total Tokens Purchased')}
              </div>
              <div
                className={
                  styles.main__table_priceper
                }>
                {t('Price per token')}
              </div>
              <div
                className={
                  styles.main__table_cost
                }>
                {t('Total Cost')}
              </div>
              {role && role !== 'SALE' ? (
                <div
                  className={
                    styles.main__table_name
                  }>
                  {t('Sales Name')}
                </div>
              ) : null}
              {role && role !== 'SALE' ? (
                <div
                  className={
                    styles.main__table_commision
                  }>
                  {t('Sales Commision')}
                </div>
              ) : null}
              {role && role === 'MAIN_SALE' ? (
                <div
                  className={
                    styles.main__table_headName
                  }>
                  {t('Head of Sales Name')}
                </div>
              ) : null}
              {role && role === 'MAIN_SALE' ? (
                <div
                  className={
                    styles.main__table_headCommision
                  }>
                  {t('Head of Sales Commision')}
                </div>
              ) : null}
              {role &&
                role !== 'HEAD_OF_SALES' && (
                  <div
                    className={
                      styles.main__table_my
                    }>
                    {t('My Commission')}
                  </div>
                )}
            </div>
            <div
              className={
                styles.main__table_values
              }>
              {clients && clients.length
                ? clients.map((item, index) => {
                    const {
                      clients_first_name,
                      clients_last_name,
                      plants,
                      total_costs,
                      sale_commissions,
                      sales_first_name,
                      sales_last_name,
                      hs_commissions,
                      hs_first_name,
                      hs_last_name,
                      my_commission,
                      clients_id,
                      clients_country,
                      clients_city,
                      clients_postcode,
                      clients_street,
                      clients_door_no,
                      hs_id,
                      hs_country,
                      hs_city,
                      hs_postcode,
                      sales_id,
                      sales_country,
                      sales_city,
                      clients_email,
                      sales_postcode,
                    } = item;
                    return (
                      <TableElement
                        role={role}
                        key={index}
                        clients_first_name={
                          clients_first_name
                        }
                        clients_last_name={
                          clients_last_name
                        }
                        plants={plants}
                        total_costs={total_costs}
                        sale_commissions={
                          sale_commissions
                        }
                        sales_first_name={
                          sales_first_name
                        }
                        sales_last_name={
                          sales_last_name
                        }
                        hs_commissions={
                          hs_commissions
                        }
                        hs_first_name={
                          hs_first_name
                        }
                        hs_last_name={
                          hs_last_name
                        }
                        my_commission={
                          my_commission
                        }
                        clients_id={clients_id}
                        clients_country={
                          clients_country
                        }
                        clients_city={
                          clients_city
                        }
                        clients_postcode={
                          clients_postcode
                        }
                        clients_street={
                          clients_street
                        }
                        clients_door_no={
                          clients_door_no
                        }
                        hs_id={hs_id}
                        clients_email={
                          clients_email
                        }
                        hs_country={hs_country}
                        hs_city={hs_city}
                        hs_postcode={hs_postcode}
                        sales_id={sales_id}
                        sales_country={
                          sales_country
                        }
                        sales_city={sales_city}
                        sales_postcode={
                          sales_postcode
                        }
                        pricePerPlant={
                          (pricePerPlant / vat) *
                          100
                        }
                      />
                    );
                  })
                : null}
            </div>
          </div>
          <Pagination
            page={page}
            perPage={10}
            setPage={setPage}
            totalItems={clientAmount}
          />
        </>
      ) : null}
      {/*  Sales Statistics  */}
      {((role === 'SALE' && salesLevel != 4) ||
        role === 'HEAD_OF_SALES') && (
        <div style={{ marginTop: '2rem' }}>
          <div className={styles.main__subheader}>
            <SectionTitle
              text={t('Sales Statistics')}
            />
          </div>
          <div className={styles.main__wrapper}>
            {isLoad ? (
              <Loader />
            ) : (
              <AdminBlock
                title={t(
                  'Total sales registered',
                )}
                value={totalSales}
                type='int'
              />
            )}
            {isLoad ? (
              <Loader />
            ) : (
              <AdminBlock
                title={t(
                  'Total tokens purchased',
                )}
                value={
                  role === 'HEAD_OF_SALES'
                    ? clientPlants
                    : totalTokenSold
                }
                type='int'
                linkText={role === 'HEAD_OF_SALES'?undefined:t(
                  'open detailed statistics',
                )}
                link={role === 'HEAD_OF_SALES'?undefined:'/admin/sales/tokens-purchased'}
                // linkText={t("open detailed statistics")}
                // link="/admin/tokens-purchased"
              />
            )}
            {isLoad ? (
              <Loader />
            ) : (
              <AdminBlock
                title={t('Total tokens cost')}
                value={
                  role === 'HEAD_OF_SALES'
                    ? clientCost
                    : totalTokenSoldPrice
                }
                subValue='EUR'
              />
            )}
          </div>
          {sales && sales.length ? (
            <>
              <div
                className={styles.main__subheader}
                style={{ marginTop: '2rem' }}>
                <SectionTitle
                  text={t(
                    'Detailed Sales Statistics',
                  )}
                />
              </div>
              <div className={styles.table}>
                <div
                  className={styles.table__head}>
                  <div>{t('Sales Name')}</div>
                  <div>{t('Sales Level')}</div>
                  <div>{t('Commission %')}</div>
                  <div>{t('Tokens Sold')}</div>
                  {/* <div>{t("Price per Token")}</div> */}
                  <div>
                    {t('Total Cost (paid)')}
                  </div>
                  <div>{t('My Comission ')}</div>
                </div>
                <div
                  className={styles.table__body}>
                     <div
              className={
                styles.main__table_values
              }>
                  {sales.map((s) => (
                    <TabeleElementSale
                    sale_from_email={s.sale_from_email}
  sale_from_first_name={s.sale_from_first_name}
  sale_from_last_name={s.sale_from_last_name}
                      my_commission_amount={
                        s.my_commission_amount
                      }
                      sale_first_name={
                        s.first_name
                      }
                     
                      sale_last_name={s.last_name}
                      sale_level={s.level}
                      sale_commission={
                        commissions[s.level]
                      }
                      tokens_sold_by_sale={
                        s.tokens_sold_by_sale
                      }
                      sale_purchase_amount={
                        s.purchase_amount
                      }
                      sale_id={s.id}
                      sales_country={s.country}
                      sales_city={s.city}
                      sales_postcode={s.postcode}
                      sale_email={s.email}
                    />
                  ))}
                </div>
                </div>
              </div>
              <Pagination
                page={pageForSales}
                perPage={10}
                setPage={setPageForSales}
                totalItems={salesTotal}
              />
            </>
          ) : null}
        </div>
      )}

      {salesLevel === 1 &&
        localStorage.getItem('isCalcShown') && (
          <div style={{ marginTop: '2rem' }}>
            <div
              className={styles.main__subheader}>
              <SectionTitle
                text={t('Clients Refer Friends')}
              />
            </div>
            <div className={styles.table}>
              <div className={styles.table__head}>
                <div>{t('Client Name')}</div>
                <div>
                  {t('Referred Client Name')}
                </div>
                <div style={{ flex: 1 }}>
                  {t('Total Tokens Purchased ')}
                </div>
                <div>{t('Date')}</div>
                <div style={{ flex: 0.5 }}>
                  {t('Total Cost')}
                </div>
                <div
                  style={{ textAlign: 'right' }}>
                  {t('My Commission (4%)')}
                </div>
              </div>
              <div className={styles.table__body}>
                {refClients.map((c) => (
                  <div
                    className={
                      styles.table__body_row
                    }
                    key={c.id}>
                    <div>{c.client_name}</div>
                    <div>
                      <div
                        className={
                          styles.mobileInfo
                        }>
                        {t(
                          'Referred Client Name',
                        )}
                      </div>
                      {c.referred_client_name}
                    </div>
                    <div style={{ flex: 1 }}>
                      <div
                        className={
                          styles.mobileInfo
                        }>
                        {t(
                          'Total Tokens Purchased ',
                        )}
                      </div>
                      {c.plants_count}&nbsp;
                      {t('tokens')}
                    </div>
                    <div>
                      <div
                        className={
                          styles.mobileInfo
                        }>
                        {t('Date')}
                      </div>
                      {currentLang?.lang == 'EN'
                        ? new Date(
                            c.purchase_date,
                          ).toLocaleDateString(
                            'en-US',
                            {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            },
                          )
                        : new Date(
                            c.purchase_date,
                          ).toLocaleDateString(
                            'de-DE',
                            {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            },
                          )}
                    </div>
                    <div style={{ flex: 0.5 }}>
                      <div
                        className={
                          styles.mobileInfo
                        }>
                        {t('Total Cost')}
                      </div>
                      {parseFloat(
                        +c.price_per_token *
                          +c.plants_count,
                      ).toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{' '}
                      EUR
                    </div>
                    <div>
                      <div
                        className={
                          styles.mobileInfo
                        }>
                        {t('My Comission')}
                      </div>
                      <div
                        style={{
                          textAlign: 'right',
                        }}>
                        {parseFloat(
                          c.commission,
                        ).toLocaleString(
                          'de-DE',
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}{' '}
                        EUR
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Pagination
              page={refClientsPage}
              perPage={10}
              setPage={setRefClientsPage}
              totalItems={totalRefClients}
            />
          </div>
        )}
    </section>
  );
};

export default Main;
