import React from "react";
import styles from "./Newspaper.module.scss";
import news1 from "../../../../img/news1.png";
import news3 from "../../../../img/news3.webp";
import news4 from "../../../../img/news4.webp";
import news2 from "../../../../img/news2.webp";
import news5 from "../../../../img/news5.png";
import news6 from "../../../../img/news6.svg";
import { useTranslation } from "react-i18next";

const newspapers = [
  {
    id: 4,
    img: news6,
    link: "https://www.wallstreet-online.de/nachricht/18391588-news-dag-tempo-hanfanbau-forschung",
  },
  {
    id: 1,
    img: news3,
    link: "https://www.ndr.de/nachrichten/mecklenburg-vorpommern/Anklam-Erste-Cannabisfarm-in-MV-entsteht,cannabisfarm100.html",
  },
  {
    id: 2,
    img: news4,
    link: "https://www.tagesschau.de/inland/regional/mecklenburgvorpommern/ndr-anklam-erste-cannabisfarm-in-mv-entsteht-100.html",
  },
  {
    id: 3,
    img: news1,
    link: "https://www.zeit.de/2024/14/cannabis-legalisierung-plantage-relzow",
  },
  {
    id: 0,
    img: news5,
    link: "https://berlinboxx.de/hanfmesse-mary-jane-in-berlin-mit-besucherrekord.html",
  },
];

const Newspaper = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.newspaper}>
      <div className="container">
        <div className={styles.newspaper__title}>{t("Known from:")}</div>
        <div className={styles.newspaper__wrapper}>
          {newspapers.map((item, i) => {
            return (
              <a href={item.link} target="_blank" rel="noreferrer" key={i}>
                <picture>
                  <source srcSet={item.img} type="image/svg+xml" />
                  <img src={item.img} alt="news" />
                </picture>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Newspaper;
