import React, { useEffect } from "react";
import styles from "./UsedCodeModal.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import usedIcon from "../../../../img/linkUsed.svg";

export const UsedCodeModal = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={styles.modal}>
      <div
        className={styles.modal__inner}
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <img className={styles.modal__inner_image} src={usedIcon} alt="link" />
        <div>
          {t(
            "This registration link has already been used. Please contact your contact person for a new link."
          )}
        </div>
        <Link to="/">{t("Close")}</Link>
      </div>
    </div>
  );
};
