import React, { useEffect, useState } from "react";
import style from "./PendingPayment.module.scss";
import { useTranslation } from "react-i18next";
import Row from "./Components/Row/Row";
import getRequest from "../../../../helpers/getRequest";

const PendingPaymentTable = () => {
  const { t } = useTranslation();
  const [payments, setPayments] = useState(null);

  useEffect(() => {
    getRequest("v1/finances/pending-payments-info").then((response) => {
      setPayments(response.data);
    });
  }, []);

  return payments && payments.length ? (
    <>
      <h2 className={style.pending__title}>{t("Open orders")}</h2>
      <div className={style.pending__table}>
        <div className={style.pending__table_header}>
          <div>{t("Order Date")}</div>
          <div>{t("Tokens")}</div>
          <div>{t("To Pay")}</div>
        </div>
        {payments.map((item) => {
          const { orderDate, toPay, tokens, id, is_coinbase } = item;
          return (
            <Row
              key={id}
              id={id}
              orderDate={orderDate}
              toPay={toPay}
              tokens={tokens}
              is_coinbase={is_coinbase}
            />
          );
        })}
      </div>
    </>
  ) : null;
};

export default PendingPaymentTable;
