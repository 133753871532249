/** @format */

import React, { useState, useEffect } from "react";
import styles from "./TableElementSale.module.scss";
import ArrowDropdown from "../../../../img/IconComponents/ArrowDropdown";
import cn from "classnames";
import { useTranslation } from "react-i18next";

const TableElementSale = ({
  my_commission_amount,
  sale_first_name,
  sale_last_name,
  sale_level,
  sale_commission,
  tokens_sold_by_sale,
  sale_purchase_amount,
  sale_id,
  sales_country,
  sales_city,
  sales_postcode,
  sale_email,
  sale_from_email,
  sale_from_first_name,
  sale_from_last_name,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();
  return (
    <div className={styles.row} onClick={() => setIsOpen((prev) => !prev)}>
      <div className={styles.row__row}>
        <div className={styles.name}>
          <ArrowDropdown rotate={isOpen ? "180deg" : ""} />
          <p>
            {sale_first_name} {sale_last_name}
          </p>
        </div>
        <div className={styles.plants}>
          <div className={styles.mobileInfo}>{t("Sales Level")}</div>
          {sale_level}
        </div>
        <div className={styles.perPrice}>
          <div className={styles.mobileInfo}>{t("Commission %")}</div>
          {sale_commission} %
        </div>
        <div className={styles.total}>
          <div className={styles.mobileInfo}>{t("Tokens Sold")}</div>
          {tokens_sold_by_sale}
        </div>
        <div className={styles.salesName}>
          <div className={styles.mobileInfo}>{t("Total Cost (paid)")}</div>
          {parseFloat(sale_purchase_amount).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          EUR
        </div>
        <div className={styles.sales}>
          <div className={styles.mobileInfo}>{t("My Comission")}</div>
          {parseFloat(my_commission_amount).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          EUR
        </div>
      </div>
      {isOpen && (
        <div className={styles.row__more}>
          <div className={styles.row__more_wrapper}>
            <div className={styles.row__more_name}>
              <div className={styles.row__more_label}>{t("Name:")}</div>
              <div>
                {sale_first_name || sale_last_name ? (
                  <>
                    {sale_first_name} {sale_last_name}
                  </>
                ) : (
                  "-"
                )}
              </div>
              <div className={styles.row__more_name_id}>{sale_id && sale_id != "" ? <>ID {sale_id}</> : "-"}</div>
            </div>

            <div className={styles.row__more_email}>
              <div className={styles.row__more_label}>{t("E-Mail:")}</div>
              <div>
                {sale_email && sale_email.length ? `${sale_email}` : ""}
              </div>
            </div>
            <div className={styles.row__more_address}>
              <div className={styles.row__more_label}>{t("Address:")}</div>
              <div>
                {sales_country && sales_country.length
                  ? `${sales_country}, `
                  : ""}
                {sales_city && sales_city.length ? `${sales_city}, ` : ""}
                {sales_postcode && sales_postcode.length
                  ? `${sales_postcode}`
                  : ""}
              </div>
            </div>
          </div>
          <div className={styles.row__more_wrapper}>
            <div className={styles.row__more_recruitedBy}>
              <div className={styles.row__more_label}>
                {(sale_level === 3
                  ? t("Recruited by level 2")
                  : sale_level === 4
                  ? t("Recruited by level 3")
                  : "") + ":"}
              </div>

              <div>
                {[3, 4].includes(sale_level)
                  ? sale_from_first_name + " " + sale_from_last_name
                  : ""}
              </div>

              <div>{[3, 4].includes(sale_level) ? sale_from_email : ""}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableElementSale;
