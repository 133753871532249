/** @format */

import React, { useState, useEffect } from "react";
import styles from "./Calculator.module.scss";
import Button from "../../../../components/Button/Button";
import AOS from "aos";
import "aos/dist/aos.css";
import nft from "../../../../img/nftDesign2.webp";
import plantVector from "../../../../img/plantVector.png";

import { useTranslation } from "react-i18next";
const range = { min: 2500, max: 40 * 2500 };

const Calculator = ({ currentLang }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(10 * 2500);
  const [plants, setPlants] = useState(10);
  const [isDectAd, setIsDectAd] = useState(localStorage.getItem("isDectAd"));

  function Trees() {
    const trees = Array.from(
      {
        length: plants,
      },
      (_, index) => (
        <div key={index}>
          <picture>
            <source srcSet={nft} type="image/webp" />
            <img src={nft} alt="tree" />
          </picture>
        </div>
      )
    );

    return <>{trees}</>;
  }

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className={styles.calculate} id="rechner">
      <div className="container">
        <div className={styles.calculate__head}>
          <h2 className={`${styles.calculate__title} ${styles.desktop}`} >
            {t("Calculate your harvest yields:")}
          </h2>
          <h2 className={`${styles.calculate__title} ${styles.mobile}`}>{t("Calculate crop yields")}</h2>
          <p className={`${styles.calculate__desc} ${styles.desktop} `}>
            {t(
              "Each DECT token is linked to a planting site NFT that represents a real plant"
            )}
          </p>
        </div>
      </div>
      <div className={styles.calculator}>
        <div className="fullContainer" style={{ paddingInline: "1rem" }}>
          <div className={styles.calculator__inner}>
            <div className={styles.calculator__left}>
              <div className={styles.calculator__trees}>
                <Trees />
              </div>
            </div>
            <div className={styles.calculator__body}>
              <div className={styles.blur}></div>
              <h3>{t("Select quantity:")}</h3>

              <div
                className={styles.calculator__body_amount}
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <div className={styles.calculator__body_amount__leaf}>
                  <img src={plantVector} alt="token" />
                </div>

                {/* <div className={styles.calculator__body_amount__trees}>
                  <span className={styles.calculator__yield}>
                    {t("Harvest share: 60%")}
                  </span>
                </div> */}
              </div>

              <div className={styles.calculator__body_slider}>
                <div className={styles.calculator__body_slider__value}>
                  <div
                    onClick={() => {
                      setAmount((prev) =>
                        prev <= range.min ? prev : prev - 2500
                      );
                      setPlants((prev) =>
                        prev <= range.min / 2500 ? prev : prev - 1
                      );
                    }}
                  >
                    <svg
                      width="36"
                      height="35"
                      viewBox="0 0 36 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="18" cy="17.5" r="17" stroke="#D9D9D9" />
                      <rect
                        x="8.5"
                        y="17"
                        width="20"
                        height="2"
                        fill="#D9D9D9"
                      />
                    </svg>
                  </div>
                  <div className={styles.calculator__body_total}>
                    {
                      <>
                        {plants === 1
                          ? `${plants} ${t("Plant")}`
                          : `${plants} ${t("Plants")}`}
                      </>
                    }
                  </div>
                  <div
                    onClick={() => {
                      setAmount((prev) =>
                        prev >= range.max ? prev : prev + 2500
                      );
                      setPlants((prev) =>
                        prev >= range.max / 2500 ? prev : prev + 1
                      );
                    }}
                  >
                    <svg
                      width="36"
                      height="35"
                      viewBox="0 0 36 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="18" cy="17.5" r="17" stroke="#D9D9D9" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.5 17V8H17.5V17H8.5V19H17.5V28H19.5V19H28.5V17H19.5Z"
                        fill="#D9D9D9"
                      />
                    </svg>
                  </div>
                </div>
                <div className={styles.calculator__body_slider__container}>
                  <input
                    type="range"
                    min={range.min}
                    max={range.max}
                    value={amount}
                    step="2500"
                    onChange={(e) => {
                      setAmount(+e.target.value);
                      setPlants(e.target.value / 2500);
                    }}
                    className={styles.slider}
                  />
                </div>
              </div>

              <div className={styles.calculator__body_scenario}>
                <div className={styles.calculator__body_scenario__info}>
                  {/* <div>
                    <p>
                      {t('Crop yield (annual)')}
                    </p>
                    <p>
                      {(amount / 2500) * 214}g
                    </p>
                  </div> */}
                  {/* <div
                    className={
                      styles.calculator__body_scenario__info_line
                    }>
                    <p>{t('Corresponds to:')}</p>
                    <hr />
                  </div> */}
                  <div>
                    <p>{t("Your harvest yields:")}</p>
                    <p>
                      {currentLang.lang == "EN"
                        ? ((amount / 2500) * 25)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        : ((amount / 2500) * 25).toString().replace(".", ",")}
                      <span>{t("g per harvest")}</span>
                    </p>
                    <p>
                      {((amount / 2500) * 100)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      <span>{t("g per year")}</span>
                    </p>
                  </div>
                </div>
              </div>

              {/* <div
                className={
                  styles.calculator__body_note
                }>
                {t('At the price of 2,35€/g')}
              </div> */}
              <div className={styles.calculator__body_power}>
                <Button
                  text={t("Secure token now")}
                  color="green"
                  to="/buy"
                />
                {localStorage.getItem("isDectAd") && (
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="currentColor"
                        d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
                      />
                    </svg>
                    2,50€/g
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calculator;
