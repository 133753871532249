import React, { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import Banner from "../MainPage/Components/Banner/Banner";
import cn from "classnames";

import styles from "./Imprint.module.scss";

import mail from "../../img/mail.svg";
import mapPin from "../../img/map-pin.svg";
import globe from "../../img/globe.svg";
import { useTranslation } from "react-i18next";

const Imprint = ({ currentLang, setCurrentLang }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.imprint}>
        <div className="container">
          <div className={styles.imprint__image}></div>
          <h2>{t("Imprint")}</h2>
          <div className={styles.imprint__wrapper}>
            <div className={cn(styles.imprint__imprint, styles.imprint__block)}>
              <h4 className={styles.imprint__block_title}>
                {t("Imprint according to § 5 TMG")}
              </h4>
              <div className={styles.imprint__block_body}>
                Deutsche Anbaugesellschaft DAG GmbH <br />
              </div>
            </div>
            <div className={cn(styles.imprint__address, styles.imprint__block)}>
              <h4 className={styles.imprint__block_title}>{t("Address")}</h4>
              <div className={styles.imprint__block_body}>
                <div>
                  <img src={mapPin} alt="map-pin" />
                  <p>
                    <p>Libnower Landstr. 1</p>
                    <p>17390 Murchin</p> {" "}
                    {t("Germany")}
                  </p>
                </div>
                <div>
                  <img src={mail} alt="mail" />
                  <p>info@anbaugesellschaft.de</p>
                </div>
                <div>+49 40 5247 4001</div>
              </div>
            </div>
            <div
              className={cn(styles.imprint__comercial, styles.imprint__block)}
            >
              <h4 className={styles.imprint__block_title}>
                {t("Commercial register")}
              </h4>
              <div className={styles.imprint__block_body}>
                {t("Deutsche Anbaugesellschaft DAG, HRB 180258")} <br />
                {t("Type of company:")} GmbH <br />
                {t("Registered office: Murchin")} <br />
                {t("Year of foundation: 2023")} <br />
                {t("VAT")}: DE362622452
              </div>
            </div>
            <div
              className={cn(styles.imprint__management, styles.imprint__block)}
            >
              <h4 className={styles.imprint__block_title}>{t("Management")}</h4>
              <div className={styles.imprint__block_body}>
                {t("Christian Tonn")} <br />
                {t(
                  "Responsible for the content of this website according to § 55"
                ) + " "}
                Abs. 2 RStV: <br />
                {t("Christian Tonn")} <br />
                <p>Libnower Landstr. 1</p>
                <p>17390 Murchin</p>
                {t("Germany")}
              </div>
            </div>
            <div
              className={cn(styles.imprint__bundesbank, styles.imprint__block)}
            ></div>

            <div className={cn(styles.imprint__terms, styles.imprint__block)}>
              <h4 className={styles.imprint__block_title}>
                {t("General Terms of Use") + " "}
                Deutsche Anbaugesellschaft DAG GmbH
              </h4>
              <div className={styles.imprint__block_body}>
                <div>
                  <img src={globe} alt="globe" />
                  <p>anbaugesellschaft.de/agb</p>
                </div>
              </div>
            </div>

            <div className={cn(styles.imprint__privacy, styles.imprint__block)}>
              <h4 className={styles.imprint__block_title}>
                {t("Privacy Policy") + " "}
                Deutsche Anbaugesellschaft DAG GmbH
              </h4>
              <div className={styles.imprint__block_body}>
                <div>
                  <img src={globe} alt="globe" />
                  <p>anbaugesellschaft.de/datenschutz/</p>
                </div>
              </div>
            </div>

            <div
              className={cn(styles.imprint__liability, styles.imprint__block)}
            >
              <h4 className={styles.imprint__block_title}>
                {t("Liability for content")}
              </h4>
              <div className={styles.imprint__block_body}>
                {t(
                  "As a service provider, we are responsible for our own content on"
                ) + " "}
                {t(
                  "this website in accordance with general laws pursuant to § 7"
                ) + " "}
                {t(
                  "para.1 TMG. However, according to §§ 8 to 10 TMG, we are not"
                ) + " "}
                {t("obligated to monitor transmitted or stored third-party") +
                  " "}
                {t(
                  "information or to investigate circumstances that indicate"
                ) + " "}
                {t(
                  "illegal activity. Liability on our part for third-party content"
                ) + " "}
                {t("or links is therefore excluded.")}
              </div>
            </div>

            <div
              className={cn(styles.imprint__liability, styles.imprint__block)}
            >
              <h4 className={styles.imprint__block_title}>
                {t("Liability for links")}
              </h4>
              <div className={styles.imprint__block_body}>
                {t(
                  "Our website contains links to external third-party websites over"
                ) + " "}
                {t(
                  "whose content we have no influence. The respective provider or"
                ) + " "}
                {t(
                  "operator of the pages is always responsible for the content of"
                ) + " "}
                {t(
                  "the linked pages. Liability on our part for external links is"
                ) + " "}
                {t(
                  "excluded. If we become aware of any legal violations, we will"
                ) + " "}
                {t("remove such links immediately.")}
              </div>
            </div>

            <div className={cn(styles.imprint__copy, styles.imprint__block)}>
              <h4 className={styles.imprint__block_title}>{t("Copyright")}</h4>
              <div className={styles.imprint__block_body}>
                {t(
                  "The content and works published on this website are subject to"
                ) + " "}
                {t(
                  "German copyright law. The reproduction, editing, distribution"
                ) + " "}
                {t(
                  "and any kind of exploitation outside the limits of copyright"
                ) + " "}
                {t(
                  "require our written consent. Downloads and copies of this site"
                ) + " "}
                {t(
                  "are only permitted for private, non-commercial use. Insofar as"
                ) + " "}
                {t(
                  "the content on this site was not created by us, the copyrights"
                ) + " "}
                {t(
                  "of third parties are respected. In particular, third-party"
                ) + " "}
                {t(
                  "content is identified as such. Should you nevertheless notice a"
                ) + " "}
                {t(
                  "copyright infringement, please inform us accordingly. If we"
                ) + " "}
                {t(
                  "become aware of any infringements, we will remove such content"
                ) + " "}
                {t("immediately.")}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Banner />
      <FooterCommon />
    </>
  );
};

export default Imprint;
