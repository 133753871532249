import React from "react";
import styles from "./SingleNews.module.scss";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import Button from "../../components/Button/Button";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import getRequest from "../../helpers/getRequest";

const SingleNews = ({ currentLang, setCurrentLang }) => {
  const [currentNew, setCurrentNew] = useState({});
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const PAGE = 1;
  const PER_PAGE = 3;
  useEffect(() => {
    try {
      getRequest(
        `v1/articles/?page=${PAGE}&perPage=${PER_PAGE}`,
        null,
        currentLang.lang
      ).then(async (response) => {
        const data = await response.data;
        // console.log(data.articles);
        setNews(data.articles.slice((PAGE-1)*PER_PAGE, (PAGE)*PER_PAGE))
      });
    } catch (err) {
      console.log(err);
    }
  }, [currentLang, id]);

  useEffect(() => {
    try {
      getRequest(`v1/articles/${id}`, null, currentLang.lang).then(
        async (response) => {
          const data = await response.data;
          // console.log("CURRENT NEW\n", data.article[0].image);
          setCurrentNew(data.article[0]);
        }
      );
    } catch (err) {
      console.log(err);
    }
    console.log(currentLang)
  }, [id, currentLang]);
  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.new}>
        <div className="container">
          <div className={styles.new__inner}>
            <div className={styles.new__body}>
              <img src={currentNew.image} alt={currentNew.title} />
              <span>
                {currentLang.lang =='EN'? new Date(currentNew.created_at).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }):new Date(currentNew.created_at).toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
              <h1 dangerouslySetInnerHTML={{ __html: currentNew.title  }}/>
              <p dangerouslySetInnerHTML={{ __html: currentNew.content }}/>
              <Button text={t("Back")} onClick={() => navigate(-1)} />
            </div>
            <div className={styles.new__aside}>
              <h3>{t("Recent Posts")}</h3>
              <div>
                {news &&
                  news.length !== 0 &&
                  news.map((n) => (
                    <div className={styles.card} key={n.id}>
                      <a href={`/news/${n.id}`}>
                        <div
                          className={styles.card__main}
                          style={{ backgroundImage: `url(${n.image})` }}
                        ></div>
                        <div className={styles.card__overlay}>
                          <h5>{n.title}</h5>
                          <span>
                            {currentLang.lang=='EN'?new Date(n.created_at).toLocaleDateString(
                              "en-US",
                              { year: "numeric", month: "long", day: "numeric" }
                            ):new Date(n.created_at).toLocaleDateString(
                              "de-DE",
                              { year: "numeric", month: "long", day: "numeric" }
                            )}
                          </span>
                        </div>
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterCommon />
    </>
  );
};

export default SingleNews;
