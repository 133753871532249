import styles from "./Main.module.scss";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import BalanceBlock from "../BalanceBlock/BalanceBlock";
import Notification from "../Notification/Notification";
import Table from "../Table/Table";
import React from "react";
import Loader from "../../../../components/Loader/Loader";
import { useTranslation } from "react-i18next";

const Main = ({ data, isLoad, role }) => {
  const { t } = useTranslation();
  return (
    <>
      {!data || isLoad ? (
        <Loader />
      ) : (
        <>
          <section className={styles.main}>
            {["HEAD_OF_SALES","SALE"].includes(role) ?<SectionTitle text={t("My Balance ")} />:<SectionTitle text={t("My Balance")} />}
            <div className={styles.main__info}>
              <div className={styles.main__row}>
                <BalanceBlock
                  label={
                    ["HEAD_OF_SALES","SALE"].includes(role)
                      ? t("Current Turnover")
                      : t("Current Balance")
                  }
                  value={data.current_balance || 0}
                  dimension="EUR"
                />
                <Notification
                  text={`${t(
                    "Auto-withdrawal every 15th of the following month."
                  )}
            ${t("Minimum amount for a withdrawal: 500 EUR")}`}
                  className="auto"
                />
              </div>
              <div className={styles.main__row}>
                <BalanceBlock
                  label={
                    ["HEAD_OF_SALES","SALE"].includes(role)
                      ? t("Pending Turnover")
                      : t("Pending Balance")
                  }
                  value={data.pending_balance || 0}
                  dimension="EUR"
                />
                <Notification
                  text={`${t(
                    "Commissions have 2 weeks pending period until they appear on the balance."
                  )}
            ${t(
              "It’s possible to withdraw only transactions that passed pending period"
            )}`}
                  className="period"
                />
              </div>
              <div className={styles.main__row}>
                <BalanceBlock
                  label={
                    ["HEAD_OF_SALES","SALE"].includes(role)
                      ? t("Total Turnover")
                      : t("Total commission")
                  }
                  value={data.total_balance || 0}
                  dimension="EUR"
                />
                  <div></div>
              </div>
            </div>
            {data && data.payouts_history && data.payouts_history?.length ? (
              <div className={styles.table}>
                <SectionTitle text={t("Payout History")} />
                <Table data={data.payouts_history} />
              </div>
            ) : null}
          </section>
        </>
      )}
    </>
  );
};

export default Main;
