import React, { useEffect, useMemo, useState } from "react";
import styles from "./Main.module.scss";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import square from "../../../../img/square.svg";
import camplant from "../../../../img/camplant.webp";
import camera1 from "../../../../img/camera1.webp";
import camera2 from "../../../../img/camera2.webp";
import camera3 from "../../../../img/camera3.webp";
import camera4 from "../../../../img/camera4.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../../../styling/swiper.css";
import standort1 from "../../../../img/standort1.webp";
import standort2 from "../../../../img/standort2.webp";
import standort3 from "../../../../img/standort3.webp";
import standort4 from "../../../../img/standort4.webp";
import getRequest from "../../../../helpers/getRequest";
import { useTranslation } from "react-i18next";
import cn from "classnames";

const Main = () => {
  const { t } = useTranslation();
  const camsPlaceholders = [
    {
      id: t("Unavailable"),
      camera_url: camera1,
      isOnline: false,
    },
    {
      id: t("Unavailable"),
      camera_url: camera2,
      isOnline: false,
    },
    {
      id: t("Unavailable"),
      camera_url: camera3,
      isOnline: false,
    },
    {
      id: t("Unavailable"),
      camera_url: camera4,
      isOnline: false,
    },
  ];

  const [activeBuilding, setActieBuilding] = useState(0);
  const [buildings, setBuildings] = useState(null);
  const [activeCamera, setActiveCamera] = useState(1);

  const { building_images, building_size, building_description, halls } =
    useMemo(
      () => buildings?.[activeBuilding + 1] || {},
      [buildings, activeBuilding]
    );

  const cams = useMemo(() => halls?.[1].cameras || camsPlaceholders, [halls]);
  const {
    camera_url,
    id: cameraId,
    updated_at,
    isOnline = true,
  } = useMemo(() => cams?.[activeCamera] || {}, [cams, activeCamera]);

  useEffect(() => {
    getRequest("v1/locations/buildings")
      .then((response) => {
        if (response.status === 200) {
          setBuildings(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section className={styles.location}>
      {buildings?.length ? (
        <div className={styles.location__buildings}>
          <div className={styles.location__buildings_title}>
            <Swiper
              spaceBetween={16}
              slidesPerView={"auto"}
              scrollbar={{ draggable: true }}
              modules={[Scrollbar]}
              // breakpoints={{
              //   // when window width is >= 320px
              //   320: {
              //     slidesPerView: 2,
              //     spaceBetween: 20,
              //   },
              //   // when window width is >= 480px
              //   768: {
              //     slidesPerView: 3,
              //     spaceBetween: 30,
              //   },
              //   // when window width is >= 640px
              //   991: {
              //     slidesPerView: 4,
              //     spaceBetween: 16,
              //   },
              //   1250: {
              //     slidesPerView: 5,
              //     spaceBetween: 16,
              //   },
              //   1500: {
              //     slidesPerView: 6,
              //     spaceBetween: 16,
              //   },
              // }}
            >
              {buildings?.map((el, i) => (
                <SwiperSlide key={i}>
                  <SectionTitle
                    text={`${t("Building")} #${i + 1}`}
                    inactive={activeBuilding !== i}
                    onClick={() => setActieBuilding(i)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={styles.location__buildings_carousel}>
            <Swiper
              spaceBetween={16}
              slidesPerView={"auto"}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              // breakpoints={{
              //   // when window width is >= 320px
              //   320: {
              //     slidesPerView: 2,
              //     spaceBetween: 20,
              //   },
              //   // when window width is >= 480px
              //   768: {
              //     slidesPerView: 2,
              //     spaceBetween: 30,
              //   },
              //   // when window width is >= 640px
              //   991: {
              //     slidesPerView: 2,
              //     spaceBetween: 16,
              //   },
              //   1330: {
              //     slidesPerView: 2,
              //     spaceBetween: 16,
              //   },
              // }}
            >
              {building_images?.map((el) => {
                const { id, image_url } = el;
                return (
                  <SwiperSlide key={id}>
                    <div
                      className={styles.img_swiper}
                      style={{ backgroundImage: `url(${image_url})` }}
                    ></div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      ) : null}
      <div className={styles.location__webcams}>
        <SectionTitle
          text={`${t("Building")} #${activeBuilding + 1} ${t("webcams")}`}
        />
        <div className={styles.location__webcams_inner}>
          <div className={styles.location__webcams_one}>
            <div className={styles.location__webcams_one__image}>
              {!isOnline ? (
                <>
                  <div className={styles.location__webcams_one__offline}>
                    {t("Live Camera will be activated soon")}
                  </div>
                </>
              ) : null}
              <img
                className={styles.location__mainCam}
                src={camera_url || camplant}
                alt="camplant"
              />
              <div className={styles.location__webcams_one__info}>
                <div>
                  {t("Webcam")} {cameraId || t("Unavailable")}
                </div>
                <div>
                  {updated_at ? t("local time") : ""}{" "}
                  {updated_at ? new Date(updated_at).toLocaleTimeString() : ""}
                </div>
              </div>
            </div>
            <div className={styles.location__webcams_one__desc}>
              {building_size ? (
                <>
                  <img src={square} alt="square" />
                  <div>
                    <h4>
                      {building_size} {t("Square Meters")}
                    </h4>
                    <p>{building_description}</p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className={styles.location__webcams_other}>
            {cams.map((cam, i) => {
              const { camera_url, id, isOnline = true } = cam;
              return (
                <div
                  className={styles.cam}
                  key={i}
                  onClick={() => (isOnline ? setActiveCamera(i) : {})}
                  style={{ cursor: isOnline ? "pointer" : "default" }}
                >
                  <div className={styles.cam__image}>
                    {isOnline ? (
                      <div className={styles.cam__online}>
                        <div></div> {t("online")}
                      </div>
                    ) : (
                      <div className={styles.cam__offline}>
                        {t("Live Camera will be activated soon")}
                      </div>
                    )}
                    <img src={camera_url} alt={`cam-${id}`} />
                  </div>
                  <p>
                    {t("Webcam")} {id}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.location__standort}>
        <div className={styles.location__standort_title}>Unser Standort</div>
        <div className={styles.location__standort_wrapper}>
          <div className={styles.location__standort_btext}>
            Die Deutsche Anbaugesellschaft DAG GmbH wird am Standort in
            Mecklenburg-Vorpommern den Genusscannabisanbau in Deutschland
            beginnen, sofern das rechtliche Fundament in Deutschland gelegt ist.
            Das Gelände umfasst eine Gesamtfläche von 630.000 qm, wovon 120.000
            qm Hallenfläche ausmacht. Über 35 geschlossene Hallen stehen der DAG
            für den Anbau von Genusscannabis zur Verfügung.
          </div>
          <div className={styles.location__standort_collect}>
            <div className={styles.location__standort_block}>
              <div className={styles.location__standort_block_main}>
                630.000 qm
              </div>
              <div className={styles.location__standort_block_sub}>
                Gesamtfläche
              </div>
            </div>
            <div className={styles.location__standort_block}>
              <div className={styles.location__standort_block_main}>
                120.000 qm
              </div>
              <div className={styles.location__standort_block_sub}>
                auf 35 Hallen verteilt
              </div>
            </div>
            <div className={styles.location__standort_block}>
              <div className={styles.location__standort_block_main}>35</div>
              <div className={styles.location__standort_block_sub}>
                geschlossene Hallen
              </div>
            </div>
          </div>
        </div>
        <div className={styles.location__standort_subtitle}>
          Stromerzeugung über eigene Photovoltaikanlage
        </div>
        <div className={styles.location__standort_text}>
          Unter Photovoltaik bezeichnet man die Umwandlung von Sonnenlicht
          mittels Solarzellen in elektrische Energie. Lange galt die
          Photovoltaik als die teuerste Form der Stromerzeugung. Dies hat sich
          im Laufe der Jahre drastisch geändert, was zum großen Teil an der
          starken Kostenreduzierung der Anlagenkomponenten liegt.
          Photovoltaikanlagen, die an Standorten mit günstigen Bedingungen
          errichtet werden, sind heute die günstigste Form der Stromerzeugung.
          Auch in Deutschland liegen die Stromerzeugungskosten von neu
          errichteten Photovoltaik- Großanlagen niedriger als bei allen anderen
          fossilen oder erneuerbaren Energien.
        </div>
        <div className={styles.location__standort_text_wrapper}>
          <div className={styles.location__standort_text}>
            An unserem Standort ist großflächig eine Photovoltaikanlage verbaut.
            Die Photovoltaikanlage an ist sowohl auf den Dach- als auch auf den
            Freiflächen installiert. Dadurch nutzen wir die maximal zur
            Verfügung stehende Fläche für die Energieerzeugung mittels
            Photovoltaik. Die gesamten Photovoltaik-Module werden im Jahr 2023
            ausgetauscht, sodass die Anlage kurzfristig wieder auf dem neusten
            technischen Stand ist und die maximale Menge an elektrischer Energie
            erzeugen kann. Die am Standort installierte Anlage wird dann eine
            Gesamtleistung von 55 MW Peak haben, was 55 Mio. kWh entspricht. Mit
            dieser Leistung kann die gesamte Cannabisproduktion zu 100% durch
            erneuerbare Energien abgedeckt werden. Durch die intensive
            Beleuchtung der Pflanzen ist der Energieverbrauch bei der
            Indoor-Cannabisproduktion extrem hoch. Dadurch ist es für uns
            besonders in heutigen Zeiten der Energiekriese, in der die Preise
            stark angestiegen und nicht stabil sind, wichtig, unabhängig von den
            großen Energieversorgern zu sein und langfristig mit günstiger und
            sauberer Energie planen zu können. Unser großer Preisvorteil für die
            großen Mengen an benötigter Energie, verschafft uns einen enormen
            Produktionsvorteil gegenüber anderen Unternehmen.
          </div>
          <div>
            <img src={standort1} alt="standort" />
          </div>
        </div>
        <div className={styles.location__standort_subtitle}>
          Recyclinganlage
        </div>
        <div
          className={cn(
            styles.location__standort_text_wrapper,
            styles.location__standort_text_wrapper_row
          )}
        >
          <div className={styles.location__standort_text_wrapper_image}>
            <img src={standort2} alt="standort" />
          </div>
          <div className={styles.location__standort_text}>
            Durch die am Standort installierten und eigens entwickelte
            Recyclinganlage können landwirtschaftliche Abfälle (Biomasse) und
            Klärschlamm in einem umweltfreundlichen Prozess zu wertvoll und
            begrenzten Rohstoffen wie z.B. Kohlenstoff und Phosphor umgewandelt
            werden. Dieser Prozess wird als hydrothermale Karbonisierung
            bezeichnet.
            <br />
            <br />
            Beim Indoor-Cannabisanbau entsteht eine große Menge an organischem
            Abfall da fast ausschließlich die Blüten der Pflanzen
            weiterverarbeitet werden. Durch die Recyclinganlage kann dieser
            organische Abfall zu wertvoller Aktivkohle umgewandelt werden, die
            später unter anderem zur Filterung von Wasser in Kläranlagen
            eingesetzt werden kann. Die durch das Recyceln von Klärschlamm
            gewonnenen Rohstoffe wie z.B. Phosphor werden zu Pflanzendünger
            weiterverarbeitet. Phosphor ist einer der wichtigsten Nährstoffe für
            Cannabispflanzen.
            <br /> <br />
            Durch das Recycling wird jegliche Art von Abfällen in wertvolle
            Rohstoffe umgewandelt, die dann wieder in den natürlichen Kreislauf
            eingebracht werden können. Die nachhaltige Bewirtschaftung des
            gesamten Standorts ist für die Deutsche Anbaugesellschaft DAG GmbH
            eines der zentralen Themen im Unternehmen.
          </div>
        </div>
        <div className={styles.location__standort_subtitle}>
          Autarke Wasserversorgung
        </div>
        <div className={styles.location__standort_text_wrapper}>
          <div className={styles.location__standort_text}>
            Der Standort verfügt über sieben eigene Brunnen und somit über eine
            autarke Wasserversorgung. Die Wasserqualität eignet sich ideal für
            den Cannabisanbau und wird ständig durch Analysen überprüft.
            <br />
            <br />
            Der Cannabisanbau benötigt große Mengen an Wasser mit der geeigneten
            Qualität. Die Cannabispflanzen müssen besonders in der kurzen und
            schnellen Wachstumsphase mit ausreichend Wasser versorgt werden.
            Auch für die großen benötigen Mengen an Dünger, die direkt vor Ort
            angemischt werden, wird viel Wasser benötigt. Für die richtige
            Cannabisaufzucht ist kaum etwas wichtiger als die richtige
            Wasserqualität und die Bewässerungstechnik. Es ist das Wasser, was
            dafür verantwortlich ist, dass die benötigten Nährstoffe und
            Sauerstoff durch die Pflanzen transportiert werden. Stimmt also
            etwas mit der Wasserqualität nicht, kann sich dies negativ auf das
            gesamte Pflanzenwachstum auswirken.
            <br />
            <br />
            Besonders der PH-Wert ist für den Cannabisanbau von großer
            Bedeutung. Cannabis kann nur in einem sehr schmalen pH-Bereich gut
            wachsen. Dieser liegt zwischen pH 6 und pH 7 liegt. Nur in diesem
            Bereich können die Pflanzen alle Nährstoffe wie z.B. Stickstoff,
            Phosphor und Calcium gut aufnehmen. Wenn der pH-Wert des Wassers nur
            geringfügig abweicht, können die Pflanzen bestimmte Nährstoffe nicht
            mehr aufnehmen, was sich sofort negativ auf das Pflanzenwachstum
            auswirkt. Wenn der pH-Wert des Wassers zum Beispiel zu hoch ist,
            können die Pflanzen kein Eisen mehr aufnehmen, während ein zu
            niedriger pH-Wert dazu führt, dass die Pflanzen nicht ausreichend
            mit Calcium und Magnesium versorgt werden.
            <br />
            <br />
            Durch eine autarke Wasserversorgung ist die ständige Versorgung mit
            dem richtigen, für den Cannabisanbau geeignetem Wasser,
            gewährleistet.
          </div>
          <div>
            <img src={standort3} alt="standort" />
          </div>
        </div>
        <div className={styles.location__standort_subtitle}>Kläranlage</div>
        <div className={styles.location__standort_text}>
          Für eine komplette autarke Bewirtschaftung gehört auch eine eigene
          Kläranlage, über die der Standort verfügt. Diese befindet sich auf dem
          neusten technischen Stand (02/2023).
        </div>
        <div className={styles.location__standort_label}>
          Bei der Wasseraufbereitung gibt es vier verschiedene Stufen.
        </div>
        <div className={styles.location__standort_wrap}>
          <div className={styles.location__standort_wrap_block}>
            <div className={styles.location__standort_wrap_block_title}>
              Die mechanische Wasseraufbereitung
            </div>
            <div className={styles.location__standort_wrap_block_text}>
              Das noch gänzlich ungeklärte Abwasser wird in einem ersten Schritt
              mechanisch gereinigt, wodurch etwa 20 – 30% der enthaltenen
              Feststoffe entfernt werden.
            </div>
          </div>
          <div className={styles.location__standort_wrap_block}>
            <div className={styles.location__standort_wrap_block_title}>
              Die chemische Abwasseraufbereitung
            </div>
            <div className={styles.location__standort_wrap_block_text}>
              In dieser Stufe nutzt man chemische Prozesse für die
              Abwasserbehandlung. Dazu werden chemische Verbindungen für die
              Herstellung der gesetzlich vorgeschriebenen Wasserrichtwerte
              eingesetzt. Zur chemischen Abwasseraufbereitung in Kläranlagen
              gehören die Neutralisation, die Desinfektion, die Phosphatfällung,
              die Stickstoffelimination, die Enteisung und die Entmanganung.
            </div>
          </div>
        </div>
        <div className={styles.location__standort_wrap}>
          <div className={styles.location__standort_wrap_block}>
            <div className={styles.location__standort_wrap_block_title}>
              Die biologische Reinigung
            </div>
            <div className={styles.location__standort_wrap_block_text}>
              Hierbei gelangt das in der mechanischen Reinigungsstufe
              vorgereinigte Wasser nun in die sogenannten Belebungsbecken, die
              häufig als Umlaufbecken angelegt sind. Hier findet die biologische
              Reinigung statt.
            </div>
          </div>
          <div className={styles.location__standort_wrap_block}>
            <div className={styles.location__standort_wrap_block_title}>
              Nanofiltration
            </div>
            <div className={styles.location__standort_wrap_block_text}>
              In der vierten und letzten Reinigungsstufe kommen Membran- und
              Filterverfahren zum Einsatz. Zum Teil wird diese Reinigungsstufe
              mit den chemischen Verfahren der Fällung und Flockung kombiniert.
              So entsteht zum Beispiel die Methode der Flockungsfiltration. Dem
              Abwasser werden Fäll- und Flockungsmittel zugesetzt, was zur
              Flockenbildung der abzuscheidenden Stoffe führt. Das Abwasser mit
              dem ausgeflockten Material wird im Anschluss über einen Tuch- oder
              Sandfilter geleitet. Es sickert langsam durch die Filterschicht.
              Dabei werden selbst kleinste organische Schwebstoffe entfernt.
            </div>
          </div>
        </div>
        <div className={styles.location__standort_text_wrapper}>
          <div>
            <img src={standort4} alt="standort" />
          </div>
          <div className={styles.location__standort_text}>
            Nach dem Durchlaufen aller Stufen werden erneut Wasserproben
            entnommen und die Wasserqualität geprüft. Erst wenn die gesetzlich
            vorgeschriebenen Parameter erfüllt sind, wird das gereinigte Wasser
            in den Wasserkreislauf zurückgeführt. Beim Cannabisanbau werden den
            Pflanzen große Mengen an Dünger hinzugefügt. Durch die
            Inbetriebnahme einer eigenen Kläranlage kann das verunreinigte
            Wasser sofort am Standort aufbereitet und wiederverwendet werden.
            Auch die eigene Aufbereitung des Wassers ist eine wichtige
            Voraussetzung für die autarke und nachhaltige Bewirtschaftung des
            Standorts.
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
