import React, { useState } from "react";
import styles from "./Questions.module.scss";

import ArrowDropdown from "../../../../img/IconComponents/ArrowDropdown";
import { useTranslation } from "react-i18next";

const Questions = () => {
  const { t } = useTranslation();

  const questionsArr = [
    {
      question: t("What is DAG's business model?"),
      answer: t(
        `In the first stage of egalization, the German cultivation company DAG (DAG) provides the infrastructure and technology for cannabis clubs with its cultivation module ('DAG-GROW'). From 01.07.2024, cannabis clubs can apply for a permit to grow cannabis in Germany. In the next 2 years, up to 5,000 cannabis clubs are expected in Germany, the demand for legally cultivated cannabis is huge. The infrastructure and technology required for cultivation pose major challenges for many cannabis clubs, as there is often a lack of capital or space, especially in urban areas. This is where DAG comes in. With fully developed cultivation areas at its huge site and the 'DECT-Grow' cultivation management system, the clubs can start cultivation immediately without any major investment. The clubs pay a monthly service fee, depending on the amount grown. DAG earns a share of every gram of cannabis grown.`
      ),
    },
    {
      question: t("What is DAG's long-term strategy?"),
      answer: t(
        `In the long term, the DAG also plans to grow cannabis itself on a large scale on site. As soon as the second stage of legalization comes into force, which regulates commercial cultivation and dispensing in licensed specialist stores, DAG will start its own cultivation. In the future, DAG also plans to supply the market with medical cannabis, both in Germany and throughout Europe. As a technology company, DAG develops blockchain-based systems for the cannabis industry. For example, the company launched the world's first blockchain-based analysis portal in 2023. Research and development is another area in which DAG is already active. Core topics are raw material utilization and the circular economy.`
      ),
    },
    {
      question: t("How does the project comply with legal regulations?"),
      answer: t(
        `Cannabis clubs wishing to cultivate cannabis in the cultivation modules in accordance with EU law require a cultivation license, which they must apply for from the responsible authority. As an infrastructure provider, DAG's activities do not require a permit or license. The project is legally supported by several law firms with whom DAG works closely. All legal requirements are complied with.`
      ),
    },
    {
      question: t("What is the DECT token and what do I have to do after purchasing it?"),
      answer: t(
        `The DECT token is legally classified as a crypto asset. It offers its holder many advantages and the opportunity to benefit from cannabis legalization and the new billion-dollar market. After purchase, DECT token holders gain access to the customer dashboard and have immediate access to CBD and cannabis products at up to 50% discount. The token will be in the user area until the end of the sale and can be transferred to a dedicated crypto exchange after the launch. With the purchase of the DECT, the buyer gains access to their own cannabis plant (via a planting site NFT), from which they will receive permanent (lifelong) harvest yields. No knowledge of crypto or cannabis is required for this; the DAG takes care of the cultivation of the cannabis plants.`
      ),
    },
    {
      question: t("How much does a DECT token cost and are there any other costs?"),
      answer: t(
        `The DECT is strictly limited and costs €2,500 including VAT. An official invoice is issued by the DAG for each purchase. For each DECT, a planting site NFT can be purchased for €1, representing a real cannabis plant and entitling its holder to a lifetime harvest. There are no further costs. The management (planting, care and harvest) of your cannabis plant by the DAG is free of charge.`
      ),
    },
    {
      question: t(
        "What functionalities and benefits does the DECT token offer?"
      ),
      answer: t(
        `With the DECT, you become part of the multi-billion dollar cannabis market and benefit directly from the success of the DAG. At the beginning of 2025, the DECT will be listed on crypto trading exchanges, where it will be tradable worldwide. In addition, the DECT offers access to your own cannabis plant (via a planting site NFT) with which you will receive lifetime yields, four times a year. The DECT also offers many other benefits, such as free visits to the huge site, access to live cameras to watch your own plant grow, participation in special events and functions and access to the DAG online store with heavily discounted cannabis products (up to 50% off).`
      ),
    },
    {
      question: t("How can I trade the DECT token and how is trading organized?"),
      answer: t(`Upon completion of the token sale (2025), buyers will be able to transfer their DECT tokens to their own crypto exchanges. With the launch, the DECT will also be tradable on international trading exchanges. These will be announced before the launch. In addition to centralized exchanges, the DECT will also be available on decentralized exchanges with sufficient liquidity. This will ensure the permanent tradability of the token at all times.`),
    },
    {
      question: t("When will cannabis cultivation begin at the DAG site?"),
      answer: t(
        `The first halls at the site are currently being expanded. Cultivation at the site is scheduled to begin in the fourth quarter of 2024.`
      ),
    },
  ];
  const [collapseQuestions, setCollapseQuestions] = useState([]);
  const handleQuestions = (i) => {
    const newCollapseQuestions = [...collapseQuestions];
    newCollapseQuestions[i] = !newCollapseQuestions[i];
    setCollapseQuestions(newCollapseQuestions);
  };
  return (
    <section className={styles.questions} id="faq">
      <div className="container">
        <div className={styles.questions__head}>
          <div>
            <h2>
              {t("Frequently asked questions (FAQ) about the DECT token")}
            </h2>
          </div>
        </div>
        <div className={styles.questions__inner}>
          <div className={styles.questions__inner_right}>
            {questionsArr.map((q, i) => {
              const { question, answer } = q;
              return (
                <div
                  className={`${styles.question} ${
                    collapseQuestions[i] === true ? styles.opened : ""
                  }`}
                  key={i}
                  onClick={() => handleQuestions(i)}
                >
                  <div className={styles.question_title}>
                    <h4>{question}</h4>
                    <ArrowDropdown />
                  </div>
                  <p>{answer}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questions;
